export enum CourseStageQuery {
  question = 'question',
}

export enum TestingStageStatus {
  completed = 'completed',
  proceed = 'proceed',
  start = 'start',
  beginning = 'beginning',
  notPassed = 'notPassed',
  notAccess = 'notAccess',
  archived = 'archived',
}
