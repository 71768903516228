import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import { CircularProgress } from '../../lab/circular_progress';
import { WarningIcon } from '../icons/warning';
import { IBlockProps } from './block.models';
import { useStyles } from './use-styles';

export const Block = <TData extends object>(props: IBlockProps<TData>): ReactElement | null => {
  const {
    data,
    error,
    loading,
    children,
    errorContent,
  } = props;
  const classes = useStyles();

  if (error) {
    if (errorContent) {
      return errorContent;
    }

    return (
      <div className={classes.root}>
        <div className={classes.error}>
          <div className={classes.errorBlock}>
            <WarningIcon width={64} height={64} />
            <div className={classes.errorMessage}>
              Неизвестная ошибка
            </div>
          </div>
          <Link className={classes.link} to={BrowserRoute.courses} >
            Перейти на страницу оценочных сессий
          </Link>
        </div>
      </div>
    );
  }

  if (loading || !data) {
    return (
      <div className={classes.root}>
        <CircularProgress width={24} height={24} />
      </div>
    );
  }

  return children(data);
};
