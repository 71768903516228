import {
  DisplayResults,
  IBuilderOrganization,
  ICourseModel,
  ISdkGetTesterResultsRes,
  TestType,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { ICourse, TStage } from '../models/stores/builder.course.store';
import {
  IChartData,
  IChartStageData,
  IExpertiseResult,
  ITestingResults,
  TStageResult,
} from '../models/stores/testing.store';
import { getLevelResultInfo } from './utils';

interface IGetResultsOpts {
  results: ISdkGetTesterResultsRes['items'];
  course: ICourse<IBuilderOrganization<string>, TStage>;
  model: ICourseModel;
}

type IExpertiseResultType = 'competency' | 'sphere';

interface ITargetLevel {
  targetValue: number;
  targetValueGrade: string;
}

interface ILevelValue {
  [key: string]: ITargetLevel;
}

interface IExpertiseListResult {
  spheres: IExpertiseResult[];
  competencies: IExpertiseResult[];
}

const MAX_PROCENT = 100;

export class TestingResultMappers {

  public static getResults(opts: IGetResultsOpts): ITestingResults {
    const { model, course, results } = opts;
    const { display_results, test_options, levels } = model;
    const { competencies, stages, title: courseTitle, testing } = course;
    const levelsFrom = levels.reduce(
      (result, level) => ({ ...result, [level.type]: level.from / MAX_PROCENT }),
      {},
    );
    const targetValue: ILevelValue = testing.reduce(
      (result, currentCourse) => ({
        ...result,
        [currentCourse._id]: { targetValue: levelsFrom[currentCourse.level], targetValueGrade: currentCourse.level },
      }),
      {},
    );

    const charts: IChartData[] = [];
    const onlySphere = display_results === DisplayResults.sphere;
    const onlyCompetency = display_results === DisplayResults.competency;
    const testTypeIsSphere = test_options.test_type === TestType.sphere;

    const getTitle = (sphereTitle: string, competencyTitle: string): string => {
      if (onlySphere) {
        return sphereTitle;
      }
      if (onlyCompetency) {
        return competencyTitle;
      }
      return `(${sphereTitle}) - ${competencyTitle}`;
    };

    const expertiseResults = competencies!.reduce<IExpertiseListResult>(
      (expertiseList, competency) => {
        const { _id: competencyId, title: competencyTitle, sphere } = competency;
        const { _id: sphereId, title: sphereTitle } = sphere;
        const chartKey = charts.length + 1;
        const expertiseId = { sphere: sphereId, competency: competencyId };
        const expertiseTitle = { sphere: sphereTitle, competency: competencyTitle };
        const chart: IChartData = {
          key: chartKey,
          title: getTitle(sphereTitle, competencyTitle),
          id: onlySphere ? sphereId : competencyId,
          grade: {},
        };
        const targetId = testTypeIsSphere ? sphereId : competencyId;

        const stagesResult = stages.reduce(
          (stageList, stage) => {
            const result = {
              sphere: results.find((res) => res?.sphere_id === sphereId && res.course_stage === stage.order),
              competency: results.find(
                (res) => res?.competence_id === competencyId && res.course_stage === stage.order,
              ),
            };

            const resultInfo = {
              sphere: getLevelResultInfo(result.sphere?.total, result.sphere?.grade),
              competency: getLevelResultInfo(result.competency?.total, result.competency?.grade),
            };

            const competencyLevel = resultInfo.competency?.competencyLevel;
            const description = competencyLevel ? competency[competencyLevel].description : 'Не пройдено';

            const getStageByType = (type: IExpertiseResultType): TStageResult => {
              chart[`${type}_${stage.order}`] = result[type]?.total;
              chart.grade[`${type}_${stage.order}`] = result[type]?.grade;

              return {
                name: stage?.name || `Этап #${stage.order}`,
                color: stage?.color || '#a4acbc',
                id: stage._id,
                levelLabel: resultInfo[type]?.levelLabel || 'Не пройдено',
              };
            };

            if (!onlyCompetency) {
              stageList.sphere.push(getStageByType('sphere'));
            }

            if (!onlySphere) {
              stageList.competency.push({ ...getStageByType('competency'), description });
            }

            return stageList;
          },
          { competency: [], sphere: [] },
        );

        const getExpertiseByType = (type: IExpertiseResultType): IExpertiseResult => ({
          id: expertiseId[type],
          expertise: expertiseTitle[type],
          stages: stagesResult[type],
        });

        if (!onlyCompetency && !expertiseList.spheres.some((sphereResult) => sphereResult.id === sphereId)) {
          expertiseList.spheres.push(getExpertiseByType('sphere'));
        }

        if (!onlySphere) {
          expertiseList.competencies.push(getExpertiseByType('competency'));
        }

        chart.targetValue = targetValue[targetId].targetValue;
        chart.grade.targetValue = targetValue[targetId].targetValueGrade;
        charts.push(chart);

        return expertiseList;
      },
      { competencies: [], spheres: [] },
    );

    return {
      ...expertiseResults,
      charts,
      stages: this.getStages(stages),
      courseTitle,
    };
  }

  private static getStages = (stages: TStage[]): IChartStageData[] => stages.map((stage) => ({
    color: stage.color,
    dataKey: stage.order,
    name: stage.name,
  }));

}
