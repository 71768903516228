import { FC, SVGProps } from 'react';
import cn from 'classnames';
import { LoaderIcon } from '../../components/icons/loader';
import { CircularProgressProps } from './circular_progress.models';

export const CircularProgress: FC<CircularProgressProps> = (props) => {
  const loaderProps: SVGProps<SVGSVGElement> = {
    ...props,
    className: cn(
      props.className,
      'animate-spin text-primary-darkBlue',
    ),
  };

  return (
    <LoaderIcon {...loaderProps} />
  );
};
