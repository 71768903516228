import {
  IBuilderOrganization,
  ICourseModel,
  ISdkGetCourseArgs,
  sdkGetCourseModel,
  QuestionsOutputMode,
  sdkGetUserCourse,
  IFieldsDisplay,
  sdkGetCourseEstTime,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_FIELDS_DISPLAY } from '../constants/builder-course.constants';
import { ResponseMappers } from '../mappers/response.mappers';
import { IBuilderCourseStore, ICourse } from '../models/stores/builder.course.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';
import { getActiveStage } from './utils';

export class BuilderCourseStore implements IBuilderCourseStore {

  public readonly rootStore: IRootStore;

  public data: ICourse<IBuilderOrganization<string>> = null;
  public loading: boolean = true;
  public error: string | undefined = undefined;
  public isProcto: boolean = false;
  public model: ICourseModel;
  public fieldsDisplay: IFieldsDisplay = DEFAULT_FIELDS_DISPLAY;
  public timeToPassTest?: string;
  public isHideAnswers?: boolean;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  public fetch = async(args: ISdkGetCourseArgs): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true;
        this.error = undefined;
        this.data = null;
      });

      const { course, user_course } = await sdkGetUserCourse(
        {
          baseURL: this.rootStore.config.builderAPI,
          token: this.rootStore.users.auth.data?.token,
          data: args,
        },
        this.rootStore.setServerTime,
      );

      const nowInMs = this.rootStore.serverTime;
      const activeStage = getActiveStage(course.stages, nowInMs);
      const courseStage = activeStage?.order;

      await Promise.all([
        this.rootStore.testing.testInfo.loadTestInfo({
          courseId: course._id,
          courseStage,
        }),
        this.getCourseModel(course.model_id),
        this.getTimeToPassTime(course._id),
      ]);

      const testInfo = this.rootStore.testing.testInfo.testInfo[courseStage]?.data;

      runInAction(() => {
        this.data = ResponseMappers.getBuilderCourse({
          course,
          nowInMs,
          testInfo,
          model: this.model,
          stagesInfo: user_course.stages,
          isActive: user_course.status.is_active,
          isHideAnswers: this.isHideAnswers,
        });
        this.isProcto = !!activeStage?.is_procto;
        this.loading = false;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.loading = false;
        this.error = getErrorMessage(error);
      });
    }
  };

  public getCourseModel = async(id: string): Promise<void> => {
    try {
      runInAction(() => {
        this.error = undefined;
      });

      const model = await sdkGetCourseModel({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.users.auth.data?.token,
        data: { id },
      });

      runInAction(() => {
        this.model = model;
        this.isHideAnswers = !this.rootStore.config.hideAnswers || model.fields_display.show_questions;
        this.fieldsDisplay = model.fields_display;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.fieldsDisplay = DEFAULT_FIELDS_DISPLAY;
      });
    }
  };

  public getTimeToPassTime = async(id:string): Promise<void> => {
    try {
      const response = await sdkGetCourseEstTime({
        baseURL: this.rootStore.config.collectorAPI,
        token: this.rootStore.users.auth.data?.token,
        data: {
          'course-id': id,
        },
      });

      this.timeToPassTest = response.values?.[0]?.estimated_time ? `${response.values[0].estimated_time} мин` : undefined;
    }
    catch (error) {
      console.error(error);
    }
  };

  public refetch = (): void => {
    this.fetch({ courseId: this.data._id });
  };

  public reset = (): void => {
    this.loading = true;
    this.error = undefined;
    this.data = null;
  };

  get isOneByOneMode(): boolean {
    return this.model?.test_options?.output === QuestionsOutputMode.byOne;
  }

}
