import { FC, SVGProps } from 'react';

export const ProctoringIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="7.5" r="2.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" />
    <path
      d="M14.4869 13.5102L14.1126 13.0097L14.4869 13.5102ZM14.5428 13.2568L15.0896 12.9541L14.5428 13.2568ZM14.1126 13.0097C12.9657 13.8672 11.5431 14.3748 10 14.3748V15.6248C11.822 15.6248 13.5056 15.0244 14.8612 14.0108L14.1126 13.0097ZM10 14.3748C8.45699 14.3748 7.0343 13.8672 5.8874 13.0096L5.13884 14.0107C6.49443 15.0243 8.17802 15.6248 10 15.6248V14.3748ZM6.00406 13.5594C6.67015 12.3559 8.17852 11.4582 10 11.4582V10.2082C7.79221 10.2082 5.82789 11.2963 4.91039 12.9541L6.00406 13.5594ZM10 11.4582C11.8215 11.4582 13.3299 12.3559 13.996 13.5594L15.0896 12.9541C14.1722 11.2963 12.2078 10.2082 10 10.2082V11.4582ZM5.8874 13.0096C6.05155 13.1324 6.1115 13.3652 6.00406 13.5594L4.91039 12.9541C4.70722 13.3212 4.81639 13.7696 5.13884 14.0107L5.8874 13.0096ZM14.8612 14.0108C15.1836 13.7697 15.2928 13.3212 15.0896 12.9541L13.996 13.5594C13.8885 13.3653 13.9485 13.1324 14.1126 13.0097L14.8612 14.0108Z"
      fill="currentColor"
    />
    <path
      d="M14.5833 2.91669H14.75C16.2428 2.91669 16.9892 2.91669 17.4529 3.38043C17.9167 3.84418 17.9167 4.59057 17.9167 6.08335V6.25002"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M14.5833 17.0833H14.75C16.2428 17.0833 16.9892 17.0833 17.4529 16.6196C17.9167 16.1558 17.9167 15.4094 17.9167 13.9166V13.75"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M5.41666 2.91669H5.24999C3.75721 2.91669 3.01082 2.91669 2.54707 3.38043C2.08332 3.84418 2.08332 4.59057 2.08332 6.08335V6.25002"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M5.41666 17.0833H5.24999C3.75721 17.0833 3.01082 17.0833 2.54707 16.6196C2.08332 16.1558 2.08332 15.4094 2.08332 13.9166V13.75"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);
