export enum EnvKeys {
  // builderAPI = 'REACT_APP_ASSMNT_BUILDERNEO_API',
  builderAPI = 'REACT_APP_ASSMNT_BUILDERNEXT_API',
  aggregatorAPI = 'REACT_APP_ASSMNT_ENGINE_AGGREGATOR_API',
  collectorAPI = 'REACT_APP_ASSMNT_ENGINE_COLLECTOR_API',
  collectorSessionsWssAPI = 'REACT_APP_ASSMNT_ENGINE_COLLECTOR_SESSIONS_WSS',
  fileStorage = 'REACT_APP_ASSMNT_S3_BUCKET_API',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  localS3Token = 'REACT_APP_LOCAL_S3_TOKEN',
  yandexMetrikaId = 'REACT_APP_YANDEX_METRIKA_ID',
  systemAPI = 'REACT_APP_ASSMNT_SYSTEM_API',
  proctoAPI = 'REACT_APP_ASSMNT_PROCTO_API',
  proctoAPIV2 = 'REACT_APP_ASSMNT_PROCTO_API_V2',
  externalProctoAPI = 'REACT_APP_ASSMNT_EXTERNAL_PROCTO_API',
  proctorEduAPI = 'REACT_APP_PROCTOR_EDU_API',
  loggerAPI = 'REACT_APP_LOGGER_API',
  loggerAPIKey = 'REACT_APP_LOGGER_API_KEY',
  flamingoAPI = 'REACT_APP_FLAMINGO_API',
  profileWeb = 'REACT_APP_PROFILE_WEB',
  hideAnswers = 'REACT_APP_HIDE_ANSWERS',
}

export interface IConfigStore {
  builderAPI: string;
  aggregatorAPI: string;
  collectorAPI: string;
  collectorSessionsWssAPI: string;
  imagesStorage: string;
  localToken: string | undefined;
  localS3Token: string | undefined;
  yandexMetrikaId: string | undefined;
  systemAPI: string;
  proctoAPI: string;
  proctoAPIV2?: string;
  externalProctoAPI: string;
  proctorEduAPI: string;
  supervisorSDK: string;
  loggerAPI: string | undefined;
  loggerAPIKey: string | undefined;
  flamingoAPI: string | undefined;
  profileWeb: string;
  hideAnswers: boolean;
}
