import React from 'react';
import { QuestionType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { EditorPreview } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/editor-preview';
import { ImageModal } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/image-modal';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { ANSWER_VARIANTS } from '../../../constants/builder-course.constants';
import { ITestingQuestionDto } from '../../../models/entities/testing.models';
import { useS3URL } from '../../../shared/use-s3-url';
import { useAppStore } from '../../../stores/context.store';
import { useStyles } from './use-styles';
import { getQuestionAnswer } from './utils';

type AnswerPreviewProps = { data: ITestingQuestionDto; index: number };

export const AnswerPreview: React.FC<AnswerPreviewProps> = (props) => {
  const { data, index } = props;
  const { config, users } = useAppStore();
  const { s3Token } = users.auth.data;
  const classes = useStyles();
  const answers = getQuestionAnswer(data);
  const imagePreview = useModalState<string | undefined>();

  const { getS3URL } = useS3URL({ baseURL: config.imagesStorage, s3Token });

  const preparedAnswers = React.useMemo(() => {
    if (!answers.length) {
      return 'Нет ответа';
    }

    if (data.type === QuestionType.matrix) {
      return Object.values(data['user-answer-id'])
        .map((id) => {
          const answerIndex = data.answers.findIndex((value) => value['answer-id'] === id);
          return ANSWER_VARIANTS[answerIndex];
        })
        .join('<hr>');
    }

    if (data.type === QuestionType.open) {
      return answers.map((el) => el['user-answer']).join('<hr>');
    }

    return answers
      .map((el) => el.answer)
      .filter(Boolean)
      .join('<hr>');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Paper className={classes.root} id={data['question-id']}>
      <div className={classes.questionWrapper}>
        <Chip color="gray" label={`Вопрос #${index + 1}`} className={classes.chip} />
        <EditorPreview className={cn(classes.editor, classes.question)} children={data.question} />
      </div>

      <div className={classes.answersWrapper}>
        <h3>Ваш ответ</h3>
        <EditorPreview className={classes.editor} children={preparedAnswers} />
        {answers?.map(
          (answer) => answer?.pictures?.length > 0 &&
            answer.pictures.map((picture) => (
              <img
                className={classes.image}
                src={getS3URL(picture)}
                alt=""
                onClick={(): void => imagePreview.open(getS3URL(picture))}
              />
            )),
        )}
        <ImageModal {...imagePreview.props} size="xl" />
      </div>
    </Paper>
  );
};
