import { FC, Fragment, PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Landing } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { HttpCodes } from '../../../models/requests/http.models';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import { CourseModal } from '../../components/course-modal';
import { BigSituationIcon } from '../../components/icons/big-situation';
import { WithFooter } from '../../components/with-footer';
import { useModalsUseStyles } from './useStyles';

export const Access: FC<PropsWithChildren> = observer((props) => {
  const { users } = useContext(appStoreContext);
  const navigate = useNavigate();
  const classes = useModalsUseStyles();

  const { data: user } = users.builder;
  const { error } = users.tester;

  const navigateToCourses = (): void => {
    users.clearRequestStatus();
    navigate(BrowserRoute.courses);
  };

  if (error && (users.requestStatus === HttpCodes.badRequest || users.requestStatus === HttpCodes.notAcceptable)) {
    return (
      <CourseModal
        defaultOpen
        onClose={navigateToCourses}
        onConfirm={navigateToCourses}
        title={<>Извините, у&nbsp;вас&nbsp;нет доступа к&nbsp;этой&nbsp;оценочный сессии</>}
        subtitle={<>Обратитесь&nbsp;к ответственному в&nbsp;вашей&nbsp;организации</>}
      />
    );
  }

  if (error && users.requestStatus === HttpCodes.forbidden) {
    return (
      <CourseModal
        defaultOpen
        onClose={navigateToCourses}
        onConfirm={navigateToCourses}
        content={
          <div className={classes.contentText}>
            К&nbsp;сожалению,&nbsp;на&nbsp;данную оценочную сессию&nbsp;больше нельзя&nbsp;зарегистрироваться.
            Достигнуто максимальное количество&nbsp;тестируемых.
          </div>
        }
      />
    );
  }

  if (error && users.requestStatus === HttpCodes.notFound) {
    return (
      <CourseModal
        defaultOpen
        modalProps={{ className: classes.resumeModal }}
        icon={<BigSituationIcon className={classes.bigSituation} />}
        onClose={navigateToCourses}
        content={
          <div className={cn(classes.resumeModalContent, classes.contentText)}>
            Раздел Поколение&nbsp;ИТ будет доступен после&nbsp;прохождения оценочной сессии
          </div>
        }
        button={<></>}
      />
    );
  }

  if ((user && user.role !== 'tester') || !user) {
    return (
      <WithFooter>
        <Landing />
      </WithFooter>
    );
  }

  return <Fragment>{props.children}</Fragment>;
});
