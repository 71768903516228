import { FC, Fragment, PropsWithChildren, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useGetUserMedia } from '../../../shared/use-get-user-media';
import { useScript } from '../../../shared/use-script';
import { useAppStore } from '../../../stores/context.store';
import { VideoPreview } from '../../components/video-preview';

export const Proctoring: FC<PropsWithChildren> = observer((props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const appStore = useAppStore();

  const {
    scriptOpts,
    setUserMedia,
  } = appStore.proctoring;

  const { showVideoPreview, ...userMedia } = useGetUserMedia({ videoRef });

  useScript(scriptOpts);

  useEffect(() => setUserMedia(userMedia), [setUserMedia, userMedia]);

  return (
    <Fragment>
      {props.children}
      {showVideoPreview && <VideoPreview videoRef={videoRef} />}
    </Fragment>
  );
});
