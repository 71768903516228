import { IProctoStatus } from '../models/entities/proctoring.models';

export const PHOTO_RESOLUTION = {
  height: 480,
  width: 640,
};

export const MOBILE_VIDEO_CONSTRAINTS = {
  height: { ideal: 900 },
  width: { ideal: 675 },
  facingMode: 'user',
};

export const CONSTRAINTS = {
  audio: false,
  video: {
    height: { ideal: PHOTO_RESOLUTION.height },
    width: { ideal: PHOTO_RESOLUTION.width },
    facingMode: 'user',
  },
};

export const TABLE_COLUMNS_NAMES = [{ name: 'course_title' }, { name: 'stage' }, { name: 'type' }, { name: 'score' }];

export const TABLE_ROW_NAME = {
  course_title: 'Название оценочной сессии',
  stage: 'Название этапа',
  type: 'Тип прокторинга',
  score: 'Результат прокторинга',
};

export const TYPE_NAME = {
  external: 'Внешний',
  internal: 'Внутренний',
  disable: 'Отключен',
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const PAGINTAION_LIMIT_OPTIONS = [10, 20];

export const ERRORS = {
  disabledCamera: 'Включите камеру',
  notFoundProctoImages:
    'Вы не можете начать оценку. Вам необходимо добавить три фотографии для прокторинга в вашем профиле',
  notFoundFace:
    'Вы не можете начать оценку, так как камера не видит ваше лицо. Сядьте прямо перед камерой, включите свет, убедитесь, что лицо хорошо освещено',
  notStart: 'Не удалось начать оценку',
};

export const PROCTO_STATUSES: Record<string, IProctoStatus> = {
  not_passed: {
    color: 'error',
    status: 'Не пройден',
  },
  need_attention: {
    color: 'warning',
    status: 'Требуется внимание',
  },
  passed: {
    color: 'success',
    status: 'Пройден',
  },
  disabled: {
    status: 'Отключен',
  },
};
