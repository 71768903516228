import { FC, PropsWithChildren, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { ErrorBoundary } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/error-boundary';
import { LOGGER_ACTION } from '../../../constants/actions';
import { getErrorMessage } from '../../../shared/error-message';
import { appStoreContext } from '../../../stores/context.store';

const useErrorBoundaryStyles = createUseStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
      fontSize: 26,
    },
  }),
  { name: 'Error' },
);

export const ErrorBoundaryWithLog: FC<PropsWithChildren> = (props) => {
  const appStore = useContext(appStoreContext);
  const classes = useErrorBoundaryStyles();

  const renderError = ({ error }: { error: Error }): JSX.Element => {
    appStore.logger.sendLog({
      action: LOGGER_ACTION.ACTIVATED_ERROR_BOUNDARY,
      request: {},
      response: { message: getErrorMessage(error) },
    });
    return <div className={classes.root}>Что-то пошло не так</div>;
  };

  return <ErrorBoundary renderError={renderError}>{props.children}</ErrorBoundary>;
};
