import React, { ReactNode } from 'react';
import { useStyles } from './use-styles';

type EmptyCardProps = React.PropsWithChildren<{ description: ReactNode; image: string }>;

export const EmptyCard: React.FC<EmptyCardProps> = (props) => {
  const classes = useStyles();
  const { description, image } = props;

  return (
    <div className={classes.root}>
      <div className={classes.empty}>
        <div className={classes.image}>
          <img src={image} alt="изображение" />
        </div>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};
