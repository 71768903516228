import { sdkGetCoursePassedTestInfo } from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { TestingMappers } from '../../mappers/testing.mappers';
import { engineErrorMessages } from '../../models/entities/testing.models';
import { IRootStore } from '../../models/stores/root.store';
import { ITestingPassedTestStore, ITestingTestState } from '../../models/stores/testing.store';
import { getErrorMessage } from '../../shared/error-message';

export class TestingPassedTestStore implements ITestingPassedTestStore {

  public readonly rootStore: IRootStore;

  public passedTest: ITestingTestState = {
    data: null,
    error: undefined,
    loading: true,
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async loadPassedTest(
    opts: { courseId: string; courseStage: string },
  ): Promise<void> {
    this.passedTest.data = null;
    this.passedTest.loading = true;
    this.passedTest.error = undefined;

    const userId = this.rootStore.users.builder.data?.id;

    if (!this.rootStore.users.auth.data || !userId) {
      return;
    }

    const data = {
      'course-id': opts.courseId,
      'course-stage': Number(opts.courseStage),
      'user-id': userId,
    };

    try {
      const response = await sdkGetCoursePassedTestInfo({
        baseURL: this.rootStore.config.aggregatorAPI,
        token: this.rootStore.users.auth.data.token,
        data,
      });
      const values = response.values[0];

      if (response.errors) {
        for (const error of response.errors) {
          toast({
            type: 'error',
            text: engineErrorMessages[error.key] || 'Что-то пошло не так',
          });
        }
      }

      if (!values) {
        throw new Error('Invalid request');
      }

      runInAction(() => {
        this.passedTest.data = TestingMappers.getPassedTest(values);
      });
    }
    catch (error) {
      runInAction(() => {
        this.passedTest.error = getErrorMessage(error);
      });
    }
    finally {
      runInAction(() => {
        this.passedTest.loading = false;
      });
    }
  }

}
