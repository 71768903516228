import { RefObject, useCallback, useRef, useState } from 'react';
import { CONSTRAINTS, MOBILE_VIDEO_CONSTRAINTS, PHOTO_RESOLUTION } from '../constants/proctoring.constants';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export interface IUserMedia {
  takeSnapshot(): string | undefined;
  handleEnableCamera(): Promise<boolean>;
  handleDisableCamera(): void;
}

interface IGetUserMediaRes extends IUserMedia {
  showVideoPreview: boolean;
}

interface IGetUserMediaOpts {
  videoRef: RefObject<HTMLVideoElement>;
}

export const useGetUserMedia = ({ videoRef }: IGetUserMediaOpts): IGetUserMediaRes => {
  const contextRef = useRef<CanvasRenderingContext2D>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream>(null);
  const [showVideoPreview, setShowVideoPreview] = useState(false);

  const handleEnableCamera = useCallback(async(): Promise<boolean> => {
    const hasGgetUserMedia = Boolean(navigator.mediaDevices?.getUserMedia);

    if (!hasGgetUserMedia) {
      return false;
    }

    setShowVideoPreview(true);

    try {
      canvasRef.current = document.createElement('canvas');

      canvasRef.current.width = PHOTO_RESOLUTION.width;
      canvasRef.current.height = PHOTO_RESOLUTION.height;

      contextRef.current = canvasRef.current.getContext('2d');

      const constraints = { ...CONSTRAINTS };

      if (isMobile) {
        constraints.video = MOBILE_VIDEO_CONSTRAINTS;
      }

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      // eslint-disable-next-line no-param-reassign
      videoRef.current.srcObject = stream;

      await videoRef.current.play();

      setMediaStream(stream);

      return true;
    }
    catch (err) {
      console.error(err);
      return false;
    }
  }, [videoRef]);

  const handleDisableCamera = useCallback((): void => {
    if (!mediaStream) {
      return;
    }

    setShowVideoPreview(false);

    const tracks = mediaStream.getTracks();

    for (const track of tracks) {
      const { readyState, kind } = track;

      if (readyState === 'live' && kind === 'video') {
        track.stop();
      }
    }
  }, [mediaStream]);

  const takeSnapshot = useCallback((): string | undefined => {
    if (!canvasRef.current || !contextRef.current) {
      return undefined;
    }

    const width = PHOTO_RESOLUTION.width;
    const height = PHOTO_RESOLUTION.height;

    contextRef.current.drawImage(videoRef.current, 0, 0, width, height);

    return canvasRef.current.toDataURL('image/jpeg');
  }, [videoRef]);

  return { takeSnapshot, handleEnableCamera, handleDisableCamera, showVideoPreview };
};
