import { sdkGetTesterResults } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { LOGGER_ACTION } from '../../constants/actions';
import { TestingResultMappers } from '../../mappers/testing-result.mappers';
import { IRootStore } from '../../models/stores/root.store';
import { ITestingResultsState, ITestingResultsStore } from '../../models/stores/testing.store';
import { getErrorMessage } from '../../shared/error-message';

export class TestingResultsStore implements ITestingResultsStore {

  public readonly rootStore: IRootStore;

  public results: ITestingResultsState = {
    data: undefined,
    error: undefined,
    loading: true,
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async loadResults(): Promise<void> {
    this.results.data = undefined;
    this.results.loading = true;
    this.results.error = undefined;

    if (!this.rootStore.users.auth.data) {
      return;
    }

    const data = {
      course_id: this.rootStore.builderCourse.data._id,
      user_id: this.rootStore.users.builder.data.id,
    };

    try {
      const { items } = await sdkGetTesterResults({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.users.auth.data.token,
        data,
      });

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.CLICK_WATCH_RESULT,
        request: data,
      });

      runInAction(() => {
        this.results.data = TestingResultMappers.getResults({
          results: items,
          course: this.rootStore.builderCourse.data,
          model: this.rootStore.builderCourse.model,
        });
      });
    }
    catch (error) {
      runInAction(() => {
        this.results.error = getErrorMessage(error);
      });
      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.ERROR_WATCH_RESULT,
        request: data,
        error,
      });
    }
    finally {
      runInAction(() => {
        this.results.loading = false;
      });
    }
  }

}
