import React from 'react';
import cn from 'classnames';
import { RadioProps } from './radio.models';
import './radio.styles.scss';

const PREFIX = 'Radio';
const classes = {
  root: PREFIX,
  input: `${PREFIX}-input`,
  preview: `${PREFIX}-preview`,
};

export const Radio: React.FC<RadioProps> = (radioProps) => {
  const { className, checked, onChange, name, defaultChecked, ...other } = radioProps;

  const rootCN = cn(classes.root, 'peer', className);
  const previewCN = cn(
    classes.preview,
    'flex items-center before:inline-block select-none before:w-6 before:h-6',
    'before:rounded-full before:border before:border-gray-400',
    'group-hover:before:border-blue-400 shrink-0',
    'peer-checked:before:bg-gradient-radial-blue-500',
    'peer-checked:before:border-blue-400',
  );
  const inputCN = cn(classes.input, 'peer cursor-pointer hidden');

  return (
    <div className={rootCN} {...other}>
      <input
        name={name}
        type="radio"
        className={inputCN}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
      />
      <div className={previewCN} />
    </div>
  );
};
