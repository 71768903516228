import React, { FC, Fragment, PropsWithChildren, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { CircularProgress } from '../../lab/circular_progress';

export const Auth: FC<PropsWithChildren> = observer((props) => {
  const { users } = useContext(appStoreContext);
  const { id } = useParams();

  const { token, s3Token } = users.auth.data ?? {};

  React.useEffect(() => {
    users.getToken();
  }, [users]);

  React.useEffect(() => {
    if (!users.auth.loading && token) {
      users.getUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, users.auth.loading]);

  const iconSize = 48;

  const wrapperCN = 'w-screen h-screen flex items-center justify-center';
  const blockCN
    = 'max-w-xs flex flex-col items-center gap-y-4 p-4 text-primary-darkBlue font-semibold text-xl text-center';

  if (users.auth.loading || users.loading) {
    return (
      <div className={wrapperCN}>
        <div className={blockCN}>
          <CircularProgress width={iconSize} height={iconSize} />
          <span>Выполняется проверка авторизации</span>
        </div>
      </div>
    );
  }

  if ((!token || !s3Token) && window.unione) {
    window.unione.goToAuth();

    return (
      <div className={wrapperCN}>
        <div className={blockCN}>
          <CircularProgress width={iconSize} height={iconSize} />
          <span>Переход на страницу авторизации...</span>
        </div>
      </div>
    );
  }

  return <Fragment>{props.children}</Fragment>;
});
