import {
  CourseStage,
  IBuilderCourse,
  IBuilderOrganization,
  ICourseModel,
  ITestInfo,
  IUserCourseStage,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { STAGE_COLORS, STAGE_STATUSES } from '../constants/builder-course.constants';
import { ICourse, TStage } from '../models/stores/builder.course.store';
import { BrowserRoute } from '../routes/browser.routes';

dayjs.extend(isBetweenPlugin);
dayjs.extend(duration);
dayjs.extend(utc);

interface IGetCourseStageStatusOpts {
  nowInMs: number;
  course: IBuilderCourse<string, IBuilderOrganization<string>>;
  testInfo: ITestInfo<Date>;
  stagesInfo: IUserCourseStage[];
  isActive?: boolean;
  model: ICourseModel;
  isHideAnswers: boolean | undefined;
}

const formatDateWithOffset = (date: string, offset:number, format: string = 'DD.MM.YYYY'): string => dayjs(date).utcOffset(offset).format(format);

export class ResponseMappers {

  public static getBuilderCourse(opts: IGetCourseStageStatusOpts): ICourse<IBuilderOrganization<string>, TStage> {
    const { nowInMs, course, testInfo, stagesInfo } = opts;
    const courseId = course._id;
    const isArchived = course.stage === CourseStage.archived;

    const stages = course.stages.map((stage) => {
      const { start_date, end_date, timezone } = stage;

      const interval = `${formatDateWithOffset(start_date, timezone)} - ${formatDateWithOffset(end_date, timezone)}`;

      const { name, color } = opts.model.stages?.find((mStage) => mStage.order === stage.order) || {};

      const preparedStage: TStage = {
        ...stage,
        name,
        color,
        interval,
      };

      // Курс заархивирован
      if (isArchived) {
        return {
          ...preparedStage,
          ...STAGE_STATUSES.archived,
        };
      }

      const currentStage = stagesInfo.find((stg) => stg.order === stage.order);
      const passedDate = currentStage?.passed_date;

      // Завершенный этап
      if (Boolean(passedDate)) {
        const stageOrder = String(currentStage.order);

        return {
          ...preparedStage,
          ...STAGE_STATUSES.completed,
          btnLabel: opts.isHideAnswers ? undefined : STAGE_STATUSES.completed.btnLabel,
          statusTitle: `Завершено ${formatDateWithOffset(passedDate, timezone)}`,
          href: BrowserRoute.courseStageAnswers(courseId, stageOrder),
        };
      }

      const startInMs = Date.parse(stage.start_date);
      const endInMs = Date.parse(stage.end_date);

      const expired = nowInMs > endInMs;

      // Этап не пройден
      if (expired) {
        return {
          ...preparedStage,
          ...STAGE_STATUSES.notPassed,
        };
      }

      const canPass = nowInMs > startInMs && nowInMs < endInMs;

      // Этап не начался
      if (!canPass) {
        return {
          ...preparedStage,
          ...STAGE_STATUSES.beginning,
          statusTitle: `Начало ${formatDateWithOffset(start_date, timezone)}`,
        };
      }

      const firstStage = stage.order === 1;
      const prevPassedDate = stagesInfo.find((stg) => stg.order === stage.order - 1)?.passed_date;

      // Предыдущий этап не пройден
      if (!firstStage && !prevPassedDate) {
        return {
          ...preparedStage,
          ...STAGE_STATUSES.notAccess,
        };
      }

      const href = BrowserRoute.courseStage(courseId, String(stage.order));
      const countOfAttempts = opts.model.common_params.count_of_attempts;

      // Можно начать этап (3 попытки)
      if (!testInfo) {
        return {
          ...preparedStage,
          ...STAGE_STATUSES.start,
          statusTitle: `Количество попыток: ${countOfAttempts}`,
          href,
        };
      }

      const attemptStartDate = testInfo['attempt-time']; // Дата начала попытки
      const testDuration = testInfo['test-duration']; // Длительность этапа в минутах
      const attemptEndDate = dayjs(attemptStartDate).add(testDuration, 'minute'); // Дата завершения попытки
      const attemptEndDateInMs = attemptEndDate.valueOf();
      const timeLeft = attemptEndDateInMs - nowInMs; // Оставшееся время в милисекундах
      const attemptLeft = countOfAttempts - testInfo.attempt; // кол-во ост. попыток

      // Продолжить
      if (timeLeft > 0) {
        return {
          ...preparedStage,
          ...STAGE_STATUSES.proceed,
          href,
          timeLeft: timeLeft / 1000,
          attemptLeft,
        };
      }

      // Можно начать этап (меньше 3-х попыток)
      if (attemptLeft > 0) {
        return {
          ...preparedStage,
          ...STAGE_STATUSES.start,
          statusTitle: `Количество попыток: ${attemptLeft}`,
          href,
        };
      }

      // Не осталось попыток и закончилось время
      return {
        ...preparedStage,
        ...STAGE_STATUSES.notPassed,
      };
    });

    return { ...course, stages, isActive: opts.isActive };
  }

  public static getBuilderCoursesList(response: IBuilderCourse<string>[], now: number): ICourse<string>[] {
    return response.map((course) => {
      const nearestStage = course.stages.find((stage) => {
        const endDateInMs = Date.parse(stage.end_date);
        return now < endDateInMs;
      });
      const lastStage = course.stages[course.stages.length - 1];

      const { order, start_date, end_date, timezone } = nearestStage || lastStage;
      const interval = `${formatDateWithOffset(start_date, timezone)} - ${formatDateWithOffset(end_date, timezone)}`;

      const currentStage = {
        interval,
        label: `Оценка ${order}`,
        color: STAGE_COLORS[order],
      };

      return {
        ...course,
        currentStage,
      };
    });
  }

}
