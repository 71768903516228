import { sdkFinishSession, sdkGenerateSession } from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { LOGGER_ACTION } from '../../constants/actions';
import { TestingMappers } from '../../mappers/testing.mappers';
import { TestingStageStatus } from '../../models/entities/builder-course.models';
import { ITestingTestDto, engineErrorMessages } from '../../models/entities/testing.models';
import { IRootStore } from '../../models/stores/root.store';
import { IStartTestAttemptsOpts, IStartTestOpts, ITestingUserTestStore } from '../../models/stores/testing.store';
import { getErrorMessage } from '../../shared/error-message';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const REDIRECT_ERROR_KEYS = [141, 144];
export class TestingUserTestStore implements ITestingUserTestStore {

  public readonly rootStore: IRootStore;

  public data: ITestingTestDto = null;
  public error: string;
  public loading: boolean = false;
  public isInsufficientDataModalOpen: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public startTest = async(opts: IStartTestOpts, attempts: IStartTestAttemptsOpts): Promise<void> => {
    const { stageOrder, status, ...proctoOpts } = opts;
    const { attemptLeft, timeLeft } = attempts;

    const isAttemptLeft = Boolean(attemptLeft);
    const data = {
      'user-id': this.rootStore.users.builder.data.id,
      'course-id': this.rootStore.builderCourse.data._id,
      'course-stage': stageOrder,
      'course-stage-id': opts.stageId,
    };

    const logData = {
      ...data,
      status,
      ...(status === TestingStageStatus.proceed && {
        'time-left': timeLeft,
      }),
    };

    try {
      runInAction(() => {
        this.loading = true;
        this.data = null;
        this.error = undefined;
      });

      await this.rootStore.proctoring.startProctoSession(proctoOpts);

      this.rootStore.logger.sendLog({
        action: attemptLeft ? LOGGER_ACTION.CLICK_CONTINUE_TESTING : LOGGER_ACTION.CLICK_START_TESTING,
        request: data,
      });

      const response = await sdkGenerateSession(
        {
          baseURL: this.rootStore.config.collectorAPI,
          token: this.rootStore.users.auth.data.token,
          data,
        },
        this.rootStore.setServerTime,
      );

      if (response.errors.length > 0) {
        const [error] = response.errors;
        const message = engineErrorMessages[error.key] || 'Что-то пошло не так';

        if (REDIRECT_ERROR_KEYS.includes(error.key)) {
          this.isInsufficientDataModalOpen = true;
        }

        throw new Error(message);
      }

      this.rootStore.logger.sendLog({
        action: isAttemptLeft ? LOGGER_ACTION.CLICK_CONTINUE_TESTING : LOGGER_ACTION.CLICK_START_TESTING,
        request: logData,
      });

      runInAction(() => {
        this.data = TestingMappers.getUserTest(response.values[0], this.rootStore.serverTime);
      });
    }
    catch (error) {
      await this.rootStore.proctoring.closeProctoSession();

      this.rootStore.logger.sendLog({
        action: isAttemptLeft ? LOGGER_ACTION.ERROR_CONTINUE_TESTING : LOGGER_ACTION.ERROR_START_TESTING,
        request: logData,
        error,
      });

      toast({
        type: 'error',
        text: getErrorMessage(error),
      });
    }
    finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  public closeTest = async(stageOrder: string): Promise<void> => {
    const data = {
      'course-id': this.rootStore.builderCourse.data?._id,
      'user-id': this.rootStore.users.builder.data.id,
    };

    try {
      runInAction(() => {
        this.error = null;
        this.loading = true;
      });

      const stages = this.rootStore.builderCourse.data?.stages;
      const activeStage = stages?.find((stage) => stage.order === Number(stageOrder));

      if (activeStage?.is_procto) {
        await this.rootStore.proctoring.closeProctoSession();
      }

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.CLICK_CLOSE_TESTING,
        request: data,
      });

      runInAction(() => {
        this.error = null;
        this.loading = false;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.ERROR_CLOSE_TESTING,
        request: data,
        error,
      });
    }
  };

  public finishTest = async(stageOrder: string): Promise<void> => {
    const order = Number(stageOrder);
    const { stages, _id: courseId } = this.rootStore.builderCourse.data;
    const activeStage = stages?.find((stage) => stage.order === order);

    const data = {
      'user-id': this.rootStore.users.builder.data.id,
      'course-id': courseId,
      'course-stage': order,
      'course-stage-id': activeStage._id,
    };

    try {
      runInAction(() => {
        this.loading = true;
      });

      if (activeStage.is_procto) {
        await this.rootStore.proctoring.finishProctoSession(activeStage._id);
      }

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.CLICK_FINISH_TESTING,
        request: data,
      });

      const response = await sdkFinishSession({
        baseURL: this.rootStore.config.collectorAPI,
        token: this.rootStore.users.auth.data.token,
        data,
      });

      if (response.errors.length > 0) {
        const [error] = response.errors;
        const message = engineErrorMessages[error.key] || 'Что-то пошло не так';
        toast({
          type: 'error',
          text: message,
        });
        throw new Error(message);
      }

      this.rootStore.builderCourse.reset();

      runInAction(() => {
        this.loading = false;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.ERROR_FINISH_TESTING,
        request: data,
        error,
      });
    }
  };

  public reset = (): void => {
    this.data = null;
  };

}
