import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      color: theme.palette.gloomyBlue.main,
      fontSize: 16,
      lineHeight: '20px',
    },
    title: {
      font: {
        family: theme.fontFamily.monserrat,
        weight: 500,
        size: 18,
      },
      lineHeight: '24px',
    },
    field: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    fieldLabel: {
      color: theme.palette.secondaryText,
      fontSize: 14,
      lineHeight: '20px',
    },
    textArea: {
      resize: 'none',
    },
    '@media (max-width: 768px)': {
      root: {
        rowGap: 32,
      },
    },
  }),
  { name: 'question-case-tree' },
);
