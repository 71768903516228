import { FC, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { IProctoringListParams, ProctoTypes } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Table, Pagination, Button, Badge } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { useThrottleFn } from 'ahooks';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { PAGINTAION_LIMIT_OPTIONS, TABLE_COLUMNS_NAMES, TABLE_ROW_NAME, TYPE_NAME } from '../../../constants/proctoring.constants';
import { IProctoringListWithProctoStatus, ITableData, TTableRow } from '../../../models/entities/proctoring.models';
import { useAppStore } from '../../../stores/context.store';
import { Block } from '../../components/block';
import { EmptyCard } from '../../components/empty-card';
import { DownloadIcon } from '../../components/icons/download';
import { FilterIcon } from '../../components/icons/filter';
import { CircularProgress } from '../../lab/circular_progress';
import { useStyles } from './use-styles';

export const ProctoringPage: FC = observer(() => {
  const classes = useStyles();
  const [params, setParams] = useState<IProctoringListParams>({ page: 1, limit: 10 });
  const appStore = useAppStore();

  const {
    loading,
    data,
    error,
    loadProctoringList,
    downloadProctoringAgreement,
    downloadProctoringResult,
  } = appStore.proctoringList;

  useEffect(() => {
    loadProctoringList({ ...params });
  }, [params, loadProctoringList]);

  const handleDownloadProctoringResult = (type: ProctoTypes, id: string): void => {
    if (type === ProctoTypes.external) {
      downloadProctoringResult(id);
    }
  };

  const { run: downloadAgreement } = useThrottleFn(downloadProctoringAgreement);

  const { run: downloadProctoResult } = useThrottleFn(handleDownloadProctoringResult);

  const maxPaginationPage = useMemo(
    () => Math.ceil((data?.totalCount || 0) / params.limit),
    [data, params.limit],
  );

  const headerRowRender = ({ name }: { name: TTableRow }): ReactNode => {
    if (TABLE_ROW_NAME[name] === TABLE_ROW_NAME.course_title) {
      return (
        <div className={classes.tableHeaderCourseName}>
          {TABLE_ROW_NAME[name]}
          <FilterIcon />
        </div>
      );
    }
    return <div className={classes.tableHeaderCols}>{TABLE_ROW_NAME[name]}</div>;
  };

  const rowRender = (column: ITableData, { className }): ReactNode => {
    const { course_title, stage, type, _id, status } = column;
    return (
      <div
        key={_id}
        className={cn(className, classes.tableBody)}
      >
        <div className={classes.tableBodyCourseName}>
          {course_title}
        </div>
        <div className={classes.tableBodyCols}>
          <Chip label={stage.name} customColor={stage.color} />
        </div>
        <div className={classes.tableBodyCols}>{TYPE_NAME[type]}</div>
        <div className={classes.tableBodyCols}>
          <Badge color={status.color}>{status.status}</Badge>
          {type === ProctoTypes.external && (
            <div
              className={classes.download}
              onClick={(): void => downloadProctoResult(type, _id)}
            >
              <DownloadIcon />
            </div>
          )}
        </div>
      </div>
    );
  };

  const goToPrevPage = (): void => {
    if (params.page > 1) {
      setParams((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  const goToNextPage = (): void => {
    if (params.page < maxPaginationPage) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const changeLimit = (limit: number): void => {
    setParams({ page: 1, limit });
  };

  return (
    <Block loading={loading} error={error} data={data}>
      {({ items, totalCount }: IProctoringListWithProctoStatus): ReactElement => {
        const proctoringListData = items.map((item) => ({ ...item, _id: item.procto_id }));
        return (
          <div className={classes.root}>
            <div className={classes.proctoring}>
              <h1 className={classes.title}>Прокторинг</h1>
              <Button onClick={downloadAgreement} className={classes.downloadLink} size="none" variantStyle="standard">
                <div>
                  <DownloadIcon />
                </div>
                Скачать согласие на обработку персональных данных
              </Button>
            </div>
            {totalCount > 0 ? (
              <div className={cn(classes.list, 'scrollbar')}>
                {loading && (
                  <div className={classes.spinner}>
                    <CircularProgress width={24} height={24} />
                  </div>
                )}
                <Table
                  className={classes.table}
                  headerRowRender={headerRowRender}
                  rowRender={rowRender}
                  data={proctoringListData}
                  columns={TABLE_COLUMNS_NAMES}
                />
                {proctoringListData.map((item) => (
                  <div key={item._id} className={classes.mobileItem}>
                    <div className={classes.mobileItemLabel}>
                      <h2 className={classes.mobileItemTitle}>{item.course_title}</h2>
                      {item.type === ProctoTypes.external && (
                        <div onClick={(): void => downloadProctoResult(item.type, item._id)}>
                          <DownloadIcon className={classes.mobileItemIcon} />
                        </div>
                      )}
                    </div>
                    <p className={classes.mobileItemInfo}>
                      <span className={classes.secondaryText}>{TABLE_ROW_NAME.stage}</span> {item.stage.name}
                    </p>
                    <p className={classes.mobileItemInfo}>
                      <span className={classes.secondaryText}>{TABLE_ROW_NAME.type}</span> {TYPE_NAME[item.type]}
                    </p>
                    <p className={classes.mobileItemInfo}>
                      <span className={classes.secondaryText}>{TABLE_ROW_NAME.score}</span> <Badge color={item.status.color}>{item.status.status}</Badge>
                    </p>
                    <hr />
                  </div>
                ))}
                <Pagination
                  limit={params.limit}
                  limitsOptions={PAGINTAION_LIMIT_OPTIONS}
                  onLimitChange={changeLimit}
                  total={totalCount}
                  minPage={1}
                  maxPage={maxPaginationPage}
                  page={params.page}
                  onNextClick={goToNextPage}
                  onPrevClick={goToPrevPage}
                />
              </div>
            ) : (
              <EmptyCard
                image="/images/proctoring.svg"
                description="В данном блоке пока отсутствует информация. По мере прохождения оценочных сессий данные будут обновляться."
              />
            )}
          </div>
        );
      }}
    </Block>
  );
});
