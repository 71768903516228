import { sdkSendLog } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable } from 'mobx';
import { ILoggerStore, ISendLogArgs } from '../models/stores/logger.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorCode, getErrorMessage } from '../shared/error-message';

export class LoggerStore implements ILoggerStore {

  public readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public sendLog = async({
    action,
    request,
    response,
    error,
  }: ISendLogArgs): Promise<void> => {
    const { loggerAPI, loggerAPIKey } = this.rootStore.config;

    if (!this.rootStore.users.auth.data || !loggerAPI || !loggerAPIKey) {
      return;
    }

    const courseId = this.rootStore.builderCourse.data?._id;
    const userId = this.rootStore.users.builder.data?.id || this.rootStore.users.tester.data?.id;
    const req = {
      'user-id': userId,
      'course-id': courseId,
      ...request,
    };

    const errorData = error ? {
      message: getErrorMessage(error),
      statusCode: getErrorCode(error),
    } : {};
    const res = {
      ...response,
      ...errorData,
    };

    await sdkSendLog({
      baseURL: loggerAPI,
      headers: {
        'x-api-key': loggerAPIKey,
      },
      token: this.rootStore.users.auth.data.token,
      data: {
        uid: userId,
        act: action,
        ts: new Date(),
        req,
        res,
      },
    });
  };

}
