import { FC, ReactNode } from 'react';
import { QuestionType, UpdateMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Checkbox } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/checkbox';
import { EditorPreview } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/editor-preview';
import { FormControlLabel } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/form_control_label';
import { ITestingAnswer, ITestingQuestionDto } from '../../../../../models/entities/testing.models';
import { Radio } from '../../../../lab/radio';
import { useStyles } from './use-styles';

interface IQuestionProps {
  question: ITestingQuestionDto;
  renderPictures(pictures: string[], type: string): ReactNode;
  handleUpdate(answerId: string, mode?: UpdateMode): void;
}

const ANSWER_ID_KEY = 'answer-id';

export const Question: FC<IQuestionProps> = ({ question, renderPictures, handleUpdate }) => {
  const classes = useStyles();

  const getControl = (el: ITestingAnswer): ReactNode => {
    if (question.type === QuestionType.plural) {
      const checked = question[`user-${ANSWER_ID_KEY}`]?.includes(el[ANSWER_ID_KEY]);
      return (
        <Checkbox
          checked={checked}
          onChange={(): void => handleUpdate(el[ANSWER_ID_KEY], checked ? UpdateMode.delete : undefined)}
        />
      );
    }

    return (
      <Radio
        name={question['question-id']}
        checked={Boolean(question[`user-${ANSWER_ID_KEY}`] === el[ANSWER_ID_KEY])}
        onChange={(): void => handleUpdate(el[ANSWER_ID_KEY])}
      />
    );
  };

  return (
    <div data-tour={`test-step-${question.type}`}>
      <div className={classes.info}>
        <p className={classes.question}>{question.question}</p>

        {renderPictures(question.pictures, 'question')}

        {question.description && <EditorPreview className={classes.editor} children={question.description} />}
      </div>

      <div className={classes.answers}>
        {question.answers.map((el) => (
          <fieldset id={question['question-id']} key={el[ANSWER_ID_KEY]}>
            <FormControlLabel
              className={classes.label}
              label={<EditorPreview children={el.answer} />}
              control={getControl(el)}
            />
            {renderPictures(el.pictures, 'answer')}
          </fieldset>
        ))}
      </div>
    </div>
  );
};
