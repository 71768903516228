export const converterDataURItoBlob = (dataURI: string): Blob | undefined => {
  if (!dataURI) {
    return undefined;
  }
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0
    ? atob(splitDataURI[1])
    : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const unitArray = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i += 1) {
    unitArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([unitArray], { type: mimeString });
};
