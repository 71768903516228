import { IBuilderCourseStage } from '@unione-pro/unione.assmnt.sdk.webapp';

export const openPageWithTextResponse = (textResponse: string): void => {
  const result = new Blob([textResponse], { type: 'text/html' });
  const url = URL.createObjectURL(result);
  const hiddenElement = document.createElement('a');
  hiddenElement.href = url;
  hiddenElement.target = '_blank';
  hiddenElement.click();
};

export const getActiveStage = (stages: IBuilderCourseStage<string>[], now: number): IBuilderCourseStage<string> => {
  const active = stages.find((stage) => {
    const start = Date.parse(stage.start_date);
    const end = Date.parse(stage.end_date);

    return start < now && now < end;
  });

  return active;
};
