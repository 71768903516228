import { FC, SVGProps } from 'react';

export const LinkIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.2514 8.74857C10.7514 8.24864 10.0732 7.9678 9.3661 7.9678C8.659 7.9678 7.98084 8.24864 7.48077 8.74857L4.8141 11.4152C4.55941 11.6612 4.35626 11.9555 4.2165 12.2808C4.07674 12.6062 4.00318 12.9561 4.0001 13.3102C3.99702 13.6642 4.06449 14.0154 4.19858 14.3431C4.33266 14.6708 4.53067 14.9686 4.78105 15.219C5.03143 15.4693 5.32917 15.6673 5.65689 15.8014C5.98461 15.9355 6.33576 16.003 6.68984 15.9999C7.04392 15.9968 7.39384 15.9233 7.71918 15.7835C8.04452 15.6437 8.33878 15.4406 8.58477 15.1859L9.31944 14.4519M8.8141 11.1859C9.31418 11.6858 9.99233 11.9667 10.6994 11.9667C11.4065 11.9667 12.0847 11.6858 12.5848 11.1859L15.2514 8.51923C15.7372 8.01629 16.006 7.34269 15.9999 6.6435C15.9938 5.9443 15.7134 5.27547 15.219 4.78105C14.7245 4.28663 14.0557 4.00618 13.3565 4.0001C12.6573 3.99403 11.9837 4.26281 11.4808 4.74856L10.7474 5.4819"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
