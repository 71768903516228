import { FC, MouseEvent as ReactMouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as faceApi from 'face-api.js';
import { CircularProgress } from '../../lab/circular_progress';
import { useStyles } from './use-styles';

const PREVIEW_HEIGHT = 100;
const PREVIEW_WIDTH = 100;
const TIME_FACE_TRACKING = 2000;
const ERROR_SHOW_DELAY = 6000;

interface IVideoPreviewProps {
  videoRef: RefObject<HTMLVideoElement>;
}

export const VideoPreview: FC<IVideoPreviewProps> = ({ videoRef }) => {
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  const videoPreview = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [hasFace, setHasFace] = useState(true);
  const [showError, setShowError] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (hasFace) {
      clearTimeout(timeoutRef.current);
      setShowError(false);

      return;
    }

    timeoutRef.current = setTimeout(() => {
      setShowError(true);
    }, ERROR_SHOW_DELAY);
  }, [hasFace]);

  useEffect(() => () => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
  }, []);

  const onVideoPlay = async(): Promise<void> => {
    setLoading(false);

    await faceApi.nets.tinyFaceDetector.loadFromUri(`${process.env.PUBLIC_URL}/models`);

    const detectAllFaces = async(): Promise<void> => {
      const detections = await faceApi.detectAllFaces(videoRef.current, new faceApi.TinyFaceDetectorOptions());
      setHasFace(Boolean(detections.length));
    };

    intervalRef.current = setInterval(detectAllFaces, TIME_FACE_TRACKING);
  };

  const onMouseDown = (event: MouseEvent | ReactMouseEvent): void => {
    event.preventDefault();

    const shiftX = event.clientX - videoPreview.current.getBoundingClientRect().left;
    const shiftY = event.clientY - videoPreview.current.getBoundingClientRect().top;

    const moveAt = (clientX: number, clientY: number): void => {
      const positionY = clientY - shiftY;
      const positionX = clientX - shiftX;

      const maxLeft = 0;
      const maxTop = 0;
      const maxRight = window.innerWidth - PREVIEW_WIDTH;
      const maxBottom = window.innerHeight - PREVIEW_HEIGHT;

      if (positionY > maxTop && positionY < maxBottom) {
        videoPreview.current.style.top = `${positionY}px`;
      }
      else if (positionY <= maxTop) {
        videoPreview.current.style.top = `${maxTop}px`;
      }
      else if (positionY >= maxBottom) {
        videoPreview.current.style.top = `${maxBottom}px`;
      }

      if (positionX > maxLeft && positionX < maxRight) {
        videoPreview.current.style.left = `${positionX}px`;
      }
      else if (positionX <= maxLeft) {
        videoPreview.current.style.left = `${maxLeft}px`;
      }
      else if (positionX >= maxRight) {
        videoPreview.current.style.left = `${maxRight}px`;
      }
    };

    moveAt(event.clientX, event.clientY);

    const onMouseMove = (ev: MouseEvent): void => {
      moveAt(ev.clientX, ev.clientY);
    };

    document.addEventListener('mousemove', onMouseMove);

    document.onmouseup = (): void => {
      document.removeEventListener('mousemove', onMouseMove);
      document.onmouseup = null;
    };
  };

  const previewCn = cn(classes.root, {
    [classes.warning]: !hasFace && !showError,
    [classes.error]: showError,
  });

  return (
    <div
      className={previewCn}
      ref={videoPreview}
      onMouseDown={onMouseDown}
    >
      {loading && <CircularProgress className={classes.loader} />}
      <video
        ref={videoRef}
        onPlay={onVideoPlay}
        className={classes.video}
        playsInline
      />
      {showError && (
        <div className={classes.errorMessage}>Не видно вашего лица или вы смотрите не в камеру</div>
      )}
    </div>
  );
};
