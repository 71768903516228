import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

interface IUseTimerOpts {
  timeLeft: number;
  finish?: () => void; // после истечения времени
}

interface IUseTimer {
  timer: string | null;
  isTimeUp: boolean;
  timeLeft: number;
  hour: string | null;
  minute: string | null;
}

export const useTimer = (opts: IUseTimerOpts): IUseTimer => {
  const intervalref = useRef<ReturnType<typeof setInterval>>(null);
  const [timeLeft, setTimeLeft] = useState<number>(opts.timeLeft);
  const [timer, setTimer] = useState<string>(null);
  const [hour, setHour] = useState<string>(null);
  const [minute, setMinute] = useState<string>(null);
  const [isTimeUp, setIsTimeUp] = useState<boolean>(false);

  const calculateLeftTime = (): void => {
    setTimeLeft((prev) => prev - 1);
  };

  const startInterval = (): void => {
    if (intervalref.current !== null) {
      return;
    }

    calculateLeftTime();

    intervalref.current = setInterval(calculateLeftTime, 1000);
  };

  const stopInterval = (): void => {
    if (intervalref.current) {
      clearInterval(intervalref.current);
      intervalref.current = null;
    }
  };

  useEffect(() => {
    if (opts.timeLeft) {
      startInterval();
    }

    return stopInterval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opts.timeLeft]);

  useEffect(() => {
    if (timeLeft > 0) {
      const time = dayjs.duration(timeLeft, 'seconds').format('HH:mm:ss');
      setTimer(time);
      setHour(dayjs.duration(timeLeft, 'seconds').format('HH'));
      setMinute(dayjs.duration(timeLeft, 'seconds').format('mm'));
    }
    else if (timeLeft !== undefined) {
      stopInterval();
      setTimer(null);
      setHour(null);
      setMinute(null);
      setIsTimeUp(true);

      if (typeof opts.finish === 'function') {
        opts.finish();
      }
    }
  }, [opts, timeLeft]);

  return { timer, isTimeUp, timeLeft, hour, minute };
};
