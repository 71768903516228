import { EnvKeys, IConfigStore } from '../models/stores/config.models';

export class ConfigStore implements IConfigStore {

  public readonly builderAPI: string;
  public readonly aggregatorAPI: string;
  public readonly collectorAPI: string;
  public readonly collectorSessionsWssAPI: string;
  public readonly localToken: string | undefined;
  public readonly localS3Token: string | undefined;
  public readonly yandexMetrikaId: string | undefined;
  public readonly systemAPI: string;
  public readonly proctoAPI: string;
  public readonly proctoAPIV2?: string;
  public readonly externalProctoAPI: string;
  public readonly proctorEduAPI: string;
  public readonly supervisorSDK: string;
  public readonly loggerAPI: string | undefined;
  public readonly loggerAPIKey: string | undefined;
  public readonly flamingoAPI: string | undefined;
  public readonly hideAnswers: boolean;
  public readonly profileWeb: string;

  public readonly imagesStorage: string;

  constructor() {
    const storage = this.getRequired(EnvKeys.fileStorage);

    this.builderAPI = this.getRequired(EnvKeys.builderAPI);
    this.aggregatorAPI = this.getRequired(EnvKeys.aggregatorAPI);
    this.collectorAPI = this.getRequired(EnvKeys.collectorAPI);
    this.collectorSessionsWssAPI = this.getRequired(EnvKeys.collectorSessionsWssAPI);
    this.localToken = this.getValue(EnvKeys.localToken);
    this.localS3Token = this.getValue(EnvKeys.localS3Token);
    this.yandexMetrikaId = this.getValue(EnvKeys.yandexMetrikaId);
    this.systemAPI = this.getRequired(EnvKeys.systemAPI);
    this.proctoAPI = this.getRequired(EnvKeys.proctoAPI);
    this.proctoAPIV2 = this.getValue(EnvKeys.proctoAPIV2);
    this.externalProctoAPI = this.getRequired(EnvKeys.externalProctoAPI);
    this.proctorEduAPI = this.getRequired(EnvKeys.proctorEduAPI);
    this.supervisorSDK = `${this.proctorEduAPI}/sdk/supervisor.js`;
    this.loggerAPI = this.getValue(EnvKeys.loggerAPI);
    this.loggerAPIKey = this.getValue(EnvKeys.loggerAPIKey);
    this.flamingoAPI = this.getValue(EnvKeys.flamingoAPI);
    this.profileWeb = this.getValue(EnvKeys.profileWeb);
    this.hideAnswers = this.getBooleanValue(EnvKeys.hideAnswers);
    this.imagesStorage = storage;
  }

  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  private getBooleanValue(key: EnvKeys): boolean {
    const value = process.env[key];

    return /^true$/.test(value?.toLowerCase());
  }

  private getRequired(key: EnvKeys): string {
    const value = this.getValue(key);
    if (typeof value !== 'string' || value.length === 0) {
      throw new Error(`Cannot find "${key}" parameter from environment`);
    }
    return value;
  }

}
