import {
  ISdkFeedbackQuestionArgs,
  sdkFeedbackQuestion,
} from '@unione-pro/unione.assmnt.sdk.webapp/lib/question/feedback-question';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/notification';
import { makeAutoObservable, runInAction } from 'mobx';
import { IQuestionFeedbackStore } from '../models/stores/questions-feedback.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage, getErrorMessages } from '../shared/error-message';

export class QuestionFeedbackStore implements IQuestionFeedbackStore {

  public readonly rootStore: IRootStore;

  public data: null = null;
  public loading: boolean = false;
  public error?: string;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public sendQuestionFeedback = async(data: ISdkFeedbackQuestionArgs): Promise<void> => {
    try {
      runInAction(() => {
        this.data = null;
        this.loading = true;
        this.error = undefined;
      });

      await sdkFeedbackQuestion({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.users.auth.data.token,
        data,
      });

      toast({
        type: 'success',
        text: 'Ваш комментарий направлен в методический отдел. Спасибо, что помогаете улучшать наши задания.',
      });

      runInAction(() => {
        this.data = null;
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });

      toast({
        type: 'error',
        text: getErrorMessages(error),
      });
    }
  };

}
