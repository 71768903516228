import { FC, SVGProps } from 'react';

export const UserIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 15.7167H14.3667C13.7 15.7167 13.0667 15.975 12.6 16.4417L11.175 17.85C10.525 18.4917 9.46668 18.4917 8.81668 17.85L7.39166 16.4417C6.925 15.975 6.28333 15.7167 5.625 15.7167H5C3.61667 15.7167 2.5 14.6083 2.5 13.2417V4.14167C2.5 2.775 3.61667 1.66667 5 1.66667H15C16.3833 1.66667 17.5 2.775 17.5 4.14167V13.2333C17.5 14.6 16.3833 15.7167 15 15.7167Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0584 7.45818C10.0251 7.45818 9.97504 7.45818 9.93337 7.45818C9.05837 7.42484 8.3667 6.71651 8.3667 5.83318C8.3667 4.93318 9.09171 4.20818 9.99171 4.20818C10.8917 4.20818 11.6167 4.94151 11.6167 5.83318C11.6251 6.71651 10.9334 7.43318 10.0584 7.45818Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.70869 9.96651C6.60036 10.7082 6.60036 11.9165 7.70869 12.6582C8.96702 13.4998 11.0337 13.4998 12.292 12.6582C13.4004 11.9165 13.4004 10.7082 12.292 9.96651C11.0337 9.13317 8.97536 9.13317 7.70869 9.96651Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
