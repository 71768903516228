import { IGrade } from '@unione-pro/unione.assmnt.sdk.webapp';
import {
  LEVEL_RESULT_INFO,
  LEVEL_RESULT_INFO_BY_GRADE,
  MIN_SCORE_LEVEL,
} from '../constants/testing-result-modal.constants';
import { ICompetencyLevelResult } from '../models/stores/testing.store';

export const getLevelResultInfo = (score?: number, grade?: IGrade): ICompetencyLevelResult | undefined => {
  if (grade) {
    return LEVEL_RESULT_INFO_BY_GRADE[grade];
  }

  if (typeof score !== 'number') {
    return undefined;
  }

  if (score >= MIN_SCORE_LEVEL.expert) {
    return LEVEL_RESULT_INFO.expert;
  }

  if (score >= MIN_SCORE_LEVEL.advanced) {
    return LEVEL_RESULT_INFO.advanced;
  }

  if (score >= MIN_SCORE_LEVEL.normal) {
    return LEVEL_RESULT_INFO.normal;
  }

  return LEVEL_RESULT_INFO.min;
};
