import { createUseStyles } from 'react-jss';

export const useResumeModalStyles = createUseStyles(
  (theme) => ({
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
      padding: {
        top: 8,
        bottom: 8,
        left: 20,
        right: 12,
      },
      fontWeight: 500,
      cursor: 'pointer',
      color: theme.palette.gloomyBlue.main,
      '&:hover': {
        backgroundColor: '#F5FBFF',
      },
    },
    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icon: {
      minWidth: 20,
      maxWidth: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#F7B055',
    },
    root: {
      fontSize: 16,
      lineHeight: '20px',
      padding: '24px 60px 60px !important',
      maxWidth: '580px !important',
      maxHeight: '100%',
      overflowY: 'auto',
    },
    closeIcon: {
      cursor: 'pointer',
      marginLeft: 'auto',
      color: theme.palette.secondaryText.main,
      width: 30,
      height: 30,
    },
    modalBody: {
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      color: theme.palette.gloomyBlue.main,
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    title: {
      font: {
        size: 28,
        weight: 700,
        family: theme.fontFamily.monserrat,
      },
      lineHeight: '36px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    checkbox: {
      cursor: 'pointer',
      display: 'flex',
      columnGap: 12,
      color: theme.palette.secondaryText.main,
    },
    btn: {
      borderRadius: '12px !important',
    },
  }),
  { name: 'ResumeModal' },
);
