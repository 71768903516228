import { FC, SVGProps } from 'react';

export const BigSituationIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="64" height="64" rx="12" fill="#F7B055" />
    <path
      d="M32 27.7337V32.0004V27.7337ZM32 36.2671H32.0107H32ZM41.6 32.0004C41.6 33.2611 41.3517 34.5094 40.8692 35.6742C40.3868 36.8389 39.6797 37.8972 38.7882 38.7886C37.8968 39.6801 36.8385 40.3872 35.6738 40.8696C34.509 41.3521 33.2607 41.6004 32 41.6004C30.7393 41.6004 29.491 41.3521 28.3262 40.8696C27.1615 40.3872 26.1032 39.6801 25.2118 38.7886C24.3203 37.8972 23.6132 36.8389 23.1308 35.6742C22.6483 34.5094 22.4 33.2611 22.4 32.0004C22.4 29.4543 23.4114 27.0125 25.2118 25.2122C27.0121 23.4118 29.4539 22.4004 32 22.4004C34.5461 22.4004 36.9879 23.4118 38.7882 25.2122C40.5886 27.0125 41.6 29.4543 41.6 32.0004Z"
      stroke="white"
      strokeWidth="1.52"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
