import { FC, ReactNode, useEffect, useState } from 'react';
import { TAnswerIds, UpdateMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { EditorPreview } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/editor-preview';
import cn from 'classnames';
import { ANSWER_VARIANTS } from '../../../../../constants/builder-course.constants';
import { ITestingQuestionCaseSequence } from '../../../../../models/entities/testing.models';
import { useStyles } from './use-styles';

interface IQuestionCaseSequenceProps {
  question: ITestingQuestionCaseSequence;
  renderPictures(pictures: string[], type: string): ReactNode;
  handleUpdate(answerId: TAnswerIds, mode?: UpdateMode): void;
}

interface ISelected {
  id: string;
  variant: string;
}

const ANSWER_ID_KEY = 'answer-id';

export const QuestionCaseSequence: FC<IQuestionCaseSequenceProps> = ({ question, renderPictures, handleUpdate }) => {
  const classes = useStyles();

  const [selected, setSelected] = useState<ISelected[]>([]);

  const handleClick = (value: string, id: string): void => {
    const updatedSelected = [...selected];

    const selectedItemIdx = selected.findIndex((item) => item.id === id);
    if (selectedItemIdx !== -1) {
      updatedSelected.splice(selectedItemIdx, 1);
    }
    else {
      updatedSelected.push({ id, variant: value });
    }

    const selectedIds = updatedSelected.reduce((acc, item, index) => {
      acc[index] = item.id;
      return acc;
    }, {});

    setSelected(updatedSelected);
    handleUpdate(selectedIds);
  };

  const handleClearSelected = (): void => {
    if (!!selected.length) {
      setSelected([]);
      handleUpdate({});
    }
  };

  useEffect(() => {
    const userAnswerIds = question[`user-${ANSWER_ID_KEY}`];

    if (!userAnswerIds) {
      handleClearSelected();
      return;
    }

    const answers = question.answers.reduce((acc, value, index) => {
      acc[value[ANSWER_ID_KEY]] = {
        id: value[ANSWER_ID_KEY],
        variant: ANSWER_VARIANTS[index],
      };
      return acc;
    }, {});

    const selectedAnswers = Object.values(userAnswerIds).map((value) => ({
      ...answers[value],
    }));

    setSelected(selectedAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  return (
    <div data-tour={`test-step-${question.type}`}>
      <div className={classes.info}>
        <p className={classes.question}>{question.question}</p>

        {renderPictures(question.pictures, 'question')}
        <EditorPreview className={classes.description} children={question.description} />
      </div>

      <div className={classes.answerWrapper}>
        {question.answers.map((answer, index) => (
          <div key={answer[ANSWER_ID_KEY]} className={classes.answerInfo}>
            <span className={classes.answerVariant}>{ANSWER_VARIANTS[index]}</span>
            <div className={classes.answerContent}>
              <span className={classes.answer} dangerouslySetInnerHTML={{ __html: answer.answer }} />
              {renderPictures(answer.pictures, 'answer')}
            </div>
          </div>
        ))}
      </div>
      <hr />

      <div className={classes.sequenceWrapper}>
        <div className={classes.sequenceAction}>
          <h4 className={classes.title}>Укажите верную последовательность:</h4>
          <button className={classes.btnDelete} onClick={handleClearSelected}>Удалить выбранные шаги</button>
        </div>
        <div className={classes.sequence}>
          <div className={classes.answerSequenceWrapper}>
            {question.answers.map((answer, index) => (
              <div
                key={answer[ANSWER_ID_KEY]}
                className={cn(classes.answerSequence, {
                  [classes.active]: selected.some(({ id }) => id === answer[ANSWER_ID_KEY]),
                })}
              >
                <span onClick={(): void => handleClick(ANSWER_VARIANTS[index], answer[ANSWER_ID_KEY])}>
                  {ANSWER_VARIANTS[index]}
                </span>
              </div>
            ))}
          </div>
          <div className={classes.numberSequenceWrapper}>
            {question.answers.map((answer) => {
              let currentCount: number = 0;
              const itHas = selected.findIndex(({ id }) => id === answer[ANSWER_ID_KEY]);
              if (itHas !== -1) {
                currentCount += itHas + 1;
              }
              return (
                <div key={answer[ANSWER_ID_KEY]} className={classes.numberSequence}>
                  <span>{currentCount}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {!!selected.length && (
        <div className={classes.selectedSequenceWrapper}>
          <h4 className={classes.title}>Ваша последовательность:</h4>
          <div className={classes.sequenceSelected}>
            {selected.map((item) => (
              <span key={item.id} className={classes.selectedSequence}>
                {item.variant}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
