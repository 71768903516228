import { FC } from 'react';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/button';
import { Modal } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal';
import { useAppStore } from '../../../../../stores/context.store';
import { CloseIcon } from '../../../icons/close';
import { useInsufficientDataModalStyles } from './use-styles';

type InsufficientDataModalProps = {
  open: boolean;
};

export const InsufficientDataModal: FC<InsufficientDataModalProps> = ({ open }) => {
  const classes = useInsufficientDataModalStyles();
  const { config } = useAppStore();

  const goToProfile = (): void => {
    window.location.href = config.profileWeb;
  };

  return (
    <Modal open={open} size="sm" className={classes.modal}>
      <div className={classes.flex}>
        <div className={classes.icon}>
          <CloseIcon />
        </div>

        <div className={classes.content}>
          <h2 className={classes.title}>Недостаточно данных для доступа к оценочной сессии</h2>
          <p className={classes.description}>
            Пожалуйста, укажите в своем профиле: СНИЛС — если вы являетесь гражданином РФ. Наименование и номер
            документа — если вы не являетесь гражданином РФ
          </p>
          <Button
            variant="primary"
            size="sm"
            onClick={goToProfile}
          >
            Перейти в профиль
          </Button>
        </div>
      </div>
    </Modal>
  );
};
