/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, HTMLAttributes, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useAppStore } from '../../../stores/context.store';
import { AnswerPreview } from '../../components/answer-preview';
import { getQuestionAnswer } from '../../components/answer-preview/utils';
import { BackLink } from '../../components/back-link';
import { PaginationScroll } from '../../components/pagination-scroll';
import { CircularProgress } from '../../lab/circular_progress';
import { ANSWERS_PAGE_STEPS } from '../demo-assessment/steps';
import { useStyles } from './use-styles';

interface ICourseStageAnswersPageProps {
  demoMode?: boolean;
}

// eslint-disable-next-line import/no-unused-modules
export const CourseStageAnswersPage: FC<ICourseStageAnswersPageProps> = observer(({ demoMode }) => {
  const { id: courseId, stage: courseStage } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const { testing, builderCourse } = useAppStore();
  const { setSteps, setCurrentStep } = useTour();
  const data = demoMode ? JSON.parse(sessionStorage.getItem('userTest')) : testing.passedTest.passedTest.data;
  const backLinkHref = demoMode ? BrowserRoute.demoAssessment : BrowserRoute.course(courseId);

  const { isHideAnswers } = builderCourse;

  useEffect(() => {
    if (isHideAnswers) {
      navigate(BrowserRoute.course(courseId));
    }
  }, [isHideAnswers]);

  useEffect(() => {
    if (demoMode) {
      setCurrentStep(0);
      setSteps(ANSWERS_PAGE_STEPS);
    }
  }, [demoMode]);

  const pagination = React.useMemo(() => {
    if (!data) {
      return [];
    }

    return data.questions.map((el, i) => ({
      label: `${i + 1}`,
      href: el['question-id'],
      answered: Boolean(getQuestionAnswer(el)),
    }));
  }, [data]);

  React.useEffect(() => {
    if (demoMode) {
      return;
    }
    testing.passedTest.loadPassedTest({ courseId, courseStage });
  }, [courseId, courseStage, testing, demoMode]);

  if (testing.passedTest.passedTest.loading && !demoMode) {
    return (
      <div className={cn(classes.loading, classes.center)}>
        <CircularProgress />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={classes.resultNotFound}>
        <BackLink to={backLinkHref} label="Назад к оценочной сессии" />
        <div className={cn(classes.center, classes.notFoundTitle)}>Нет результатов</div>
      </div>
    );
  }

  return (
    <div className={classes.root} data-tour="answer-step-1">
      <BackLink data-tour="answer-step-2" to={backLinkHref} label="Назад к оценочной сессии" />

      <PaginationScroll
        containerId="answers-scroll"
        options={pagination}
        getOptionProps={(el): HTMLAttributes<HTMLDivElement> => ({
          className: cn({ [classes.borderBlue]: el.answered }),
        })}
      />

      <div className={cn(classes.answersList, 'scrollbar')} id="answers-scroll">
        {data.questions.map((el, i) => (
          <AnswerPreview key={`question-${el['question-id']}`} data={el} index={i} />
        ))}
      </div>
    </div>
  );
});
