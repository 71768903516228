import { ITestingQuestionDto } from '../../../models/entities/testing.models';

export const getAnswersCount = (questions: ITestingQuestionDto[]): number => questions.filter((el) => {
  const userAnswer = el?.['user-answer'] as string || el?.['user-answer-id'];

  if (!Array.isArray(userAnswer) && typeof userAnswer === 'object') {
    return Object.keys(userAnswer || {}).length;
  }
  return userAnswer?.length;
}).length;

export enum confirmMessages {
  default = 'Вы уверены, что хотите завершить оценку? Все ваши попытки будут использованы, а изменить ответы после завершения будет невозможно!',
  custom = 'Вы ответили на все вопросы, но если не завершите оценку, то ответы будут удалены!</br></br>Завершить оценку?',
}
