import { IProctoringListParams, sdkGetProctoringAgreement, sdkGetProctoringCourseList, sdkGetProctoringResult } from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { ProctoringMappers } from '../mappers/proctoring.mappers';
import { IProctoringListWithProctoStatus } from '../models/entities/proctoring.models';
import { IProctoringListStore } from '../models/stores/proctoring.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';
import { openPageWithTextResponse } from './utils';

export class ProctoringListStore implements IProctoringListStore {

  public readonly rootStore: IRootStore;

  public data: null | IProctoringListWithProctoStatus = null;
  public error: undefined | string = undefined;
  public loading: boolean = true;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  public loadProctoringList = async(params: IProctoringListParams): Promise<void> => {
    this.loading = true;
    this.error = undefined;

    try {
      const response = await sdkGetProctoringCourseList({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.users.auth.data.token,
        data: { ...params, sort: 'event_date_time,-1' },
      });

      runInAction(() => {
        this.data = ProctoringMappers.addProctoStatus(response);
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  };

  public downloadProctoringAgreement = async(): Promise<void> => {
    try {
      const response = await sdkGetProctoringAgreement({
        baseURL: this.rootStore.config.systemAPI,
        data: {
          authId: this.rootStore.users.builder.data.id,
        },
      });
      openPageWithTextResponse(response);
    }
    catch (error) {
      console.error(error);
      toast({
        type: 'error',
        text: getErrorMessage(error),
      });
    }
  };

  public downloadProctoringResult = async(procto_id: string): Promise<void> => {
    try {
      const response = await sdkGetProctoringResult({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.users.auth.data.token,
        data: { procto_id },
      });
      openPageWithTextResponse(response);
    }
    catch (error) {
      console.error(error);
      toast({
        type: 'error',
        text: getErrorMessage(error),
      });
    }
  };

}
