import { createUseStyles } from 'react-jss';


export const useStyles = createUseStyles((theme) => ({
  question: {
    color: theme.palette.gloomyBlue.main,
    font: {
      size: 18,
      weight: 500,
    },
    lineHeight: '24px',
  },
  editor: {
    color: '#6F7C98',
    fontSize: 16,
    padding: '0px',
    lineHeight: '20px',
    marginTop: 16,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  type: {
    marginTop: 8,
  },
  answers: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 16,
  },
  label: {
    alignItems: 'center',
  },
}));
