import { createUseStyles } from 'react-jss';

export const useModalsUseStyles = createUseStyles(
  (theme) => ({
    contentText: {
      textAlign: 'center',
      color: theme.palette.gloomyBlue.main,
    },
    resumeModal: {
      maxWidth: '520px !important',
      rowGap: '8px !important',
      borderTop: '0 !important',
      padding: '32px 32px 40px 32px !important',
    },
    resumeModalContent: {
      fontFamily: theme.fontFamily.monserrat,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '24px',
    },
    bigSituation: {
      marginBottom: 24,
      alignSelf: 'center',
    },
  }),
  { name: 'Modal' },
);
