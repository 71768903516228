import { FC, SVGProps } from 'react';

export const FilterIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.666672 0.333496H11.3333V1.66683H0.666672V0.333496ZM0.666672 4.41683H7.77778V5.66683H0.666672V4.41683ZM0.666672 8.3335H11.3333V9.66683H0.666672V8.3335Z"
      fill="currentColor"
    />
  </svg>
);
