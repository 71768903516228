import { sdkGetTesterCourses } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx';
import { ResponseMappers } from '../../mappers/response.mappers';
import { IRootStore } from '../../models/stores/root.store';
import { ITestingCoursesState, ITestingCoursesStore } from '../../models/stores/testing.store';
import { getErrorCode, getErrorMessage } from '../../shared/error-message';

export class TestingCoursesStore implements ITestingCoursesStore {

  public readonly rootStore: IRootStore;

  public courses: ITestingCoursesState = {
    data: [],
    error: undefined,
    errorCode: undefined,
    loading: true,
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
    onBecomeObserved(this, 'courses', this.loadCourses);
  }

  public loadCourses = async(): Promise<void> => {
    this.courses.data = [];
    this.courses.loading = true;
    this.courses.error = undefined;

    if (!this.rootStore.users.auth.data) {
      return;
    }

    try {
      const response = await sdkGetTesterCourses(
        {
          baseURL: this.rootStore.config.builderAPI,
          token: this.rootStore.users.auth.data.token,
        },
        this.rootStore.setServerTime,
      );

      runInAction(() => {
        this.courses.data = ResponseMappers.getBuilderCoursesList(response, this.rootStore.serverTime);
      });
    }
    catch (error) {
      runInAction(() => {
        this.courses.error = getErrorMessage(error);
        this.courses.errorCode = getErrorCode(error);
      });
    }
    finally {
      runInAction(() => {
        this.courses.loading = false;
      });
    }
  };

}
