import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    flexCenter: {
      flexGrow: 1,
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fontSize24: {
      fontSize: 24,
    },
    fontSize12: {
      fontSize: 12,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      margin: {
        top: 24,
        left: 24,
        right: 24,
      },
      padding: {
        left: 24,
        right: 24,
      },
      minHeight: 'calc(100% - 44px)',
    },
    title: {
      fontSize: 28,
      fontWeight: 700,
      color: theme.palette.gloomyBlue.main,
    },
    content: {
      display: 'flex',
      position: 'relative',
      gap: 24,
    },
    loader: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backdropFilter: 'blur(0.7px)',
      transition: 'ease 0.3s',
    },
    questions: {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 235px)',
      width: '100%',
    },
    actions: {
      width: 104,
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      color: theme.palette.secondaryText.main,
    },
    progress: {
      textAlign: 'center',
      padding: {
        top: 24,
        bottom: 16,
        left: 16,
        right: 16,
      },
    },
    progressBar: {
      width: '100%',
      height: 8,
      backgroundColor: '#E6F1FB',
      borderRadius: 20,
      marginBottom: 8,
    },
    percent: {
      borderRadius: 20,
      height: '100%',
      transition: 'ease 0.2s',
      backgroundImage: `linear-gradient(135deg, ${theme.gradient.blue.start}, ${theme.gradient.blue.end}, ${theme.gradient.blue.start})`,
    },
    buttons: {
      padding: 8,
      fontSize: 12,
      lineHeight: '16px',
    },
    btn: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: 8,

      '&:hover': {
        color: '#334155',
      },
    },
    btnIcon: {
      height: 20,
      width: 20,
    },
    timer: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'center',
      padding: 16,
    },
    '@keyframes heartbeat': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.06) translateX(1%) translateY(1%)',
      },
    },
    animate: {
      animation: '$heartbeat 1s infinite',
      backgroundColor: '#2fb62f',
      color: 'white',
      borderRadius: 8,
    },
    questionList: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    modalTitle: {
      fontSize: 24,
      fontFamily: theme.fontFamily.monserrat,
      fontWeight: 600,
      lineHeight: '28px',
      marginBottom: 10,
    },
    modalTimerBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 12,
      background: '#F5F6F8',
      padding: '12px 16px',
      gap: 4,
    },
    modalTimer: {
      display: 'flex',
      gap: 8,
    },
    modalTimerContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    modalTimerValue: {
      background: '#FFFFFF',
      padding: 12,
      borderRadius: 12,
      fontFamily: theme.fontFamily.monserrat,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
    },
    modalTimerLabel: {
      fontSize: 12,
      lineHeight: '16px',
      color: theme.palette.secondaryText.main,
      textAlign: 'center',
    },
    modalTimerDivider: {
      fontFamily: theme.fontFamily.monserrat,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
      padding: '12px 0',
    },
    closeIcon: {
      cursor: 'pointer',
      marginLeft: 'auto',
      color: theme.palette.secondaryText.main,
      width: 30,
      height: 30,
    },
    confirmIcon: {
      margin: '0 auto',
    },
    confirmContent: {
      textAlign: 'center',
      color: theme.palette.gloomyBlue.main,
    },
    answered: {
      borderColor: '#3b83f6',
    },
    active: {
      backgroundColor: '#eff6ff',
    },
    pagination: {
      order: 0,
    },
    modal: {},
    confirm: {},
    '@media (max-width: 1320px)': {
      questions: {
        maxHeight: 'calc(100vh - 290px)',
      },
      root: {
        padding: 0,
      },
    },
    '@media only screen and (max-device-width: 1024px)': {
      questions: {
        maxHeight: 'calc(100vh - 370px)',
      },
      content: {
        flexDirection: 'column',
      },
      actions: {
        padding: '14px 16px',
        width: '100%',
        backgroundColor: 'white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        boxShadow: '0px 0px 70px rgba(44, 46, 50, 0.2)',
        flexDirection: 'row',
        gap: 32,
      },
      progress: {
        display: 'none',
      },
      timer: {
        display: 'flex',
        flexBasis: '33%',
        alignItems: 'center',
        justifyContent: 'center',
      },
      buttons: {
        boxShadow: 'none',
        padding: 0,
        flexGrow: 1,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 32,
      },
      btn: {
        padding: 0,
        gap: 6,
      },
      pagination: {
        order: '-1',
      },
      modal: {
        height: '100%',
        maxWidth: '100%',
        '& svg:first-child': {
          marginBottom: 'auto',
          background: '#F5F5F7',
          borderRadius: '50%',
        },
        '& button': {
          marginTop: 'auto',
        },
      },
      confirm: {
        height: '100%',
        maxWidth: '100%',
        '& > div': {
          '&:first-child': {
            marginBottom: 'auto',
            '& > button': {
              background: '#F5F5F7',
              borderRadius: '50%',
            },
          },
          '&:last-child': {
            marginTop: 'auto',
          },
        },
      },
    },
    '@media (max-width: 768px)': {
      title: {
        fontSize: 24,
        lineHeight: '28px',
        fontWeight: 600,
      },
      questions: {
        maxHeight: 'calc(100vh - 354px)',
      },
    },
    '@media (max-width: 640px)': {
      questions: {
        maxHeight: 'calc(100vh - 294px)',
      },
      root: {
        margin: '0 -24px',
        padding: 0,
      },
      title: {
        padding: '0 24px',
      },
      pagination: {
        margin: '0 24px',
      },
    },
    '@media (max-width: 556px)': {
      questions: {
        maxHeight: 'calc(100vh - 299px)',
      },
    },
  }),
  { name: 'CourseStagePage' },
);
