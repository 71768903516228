import { useEffect } from 'react';

export interface IUseScriptOpts {
  src: string;
  onload: () => void;
}

export const useScript = (opts?: IUseScriptOpts): void => {
  useEffect(() => {
    if (!opts?.src) {
      return undefined;
    }

    const script = document.createElement('script');

    script.src = opts.src;
    script.async = true;
    script.onload = opts.onload;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [opts]);
};
