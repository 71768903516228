import {
  IUserBuilder,
  sdkGetUser,
  sdkGetAuthLink,
  ITester,
  sdkAssignTester,
  IAgreements,
  sdkUpdateAgreements,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/notification';
import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { LOGGER_ACTION } from '../constants/actions';
import { IUserToken } from '../models/entities/user.models';
import { ICommonStore } from '../models/stores/common.store';
import { IRootStore } from '../models/stores/root.store';
import { IUserStore } from '../models/stores/users.store';
import { getErrorMessage } from '../shared/error-message';

export class UserStore implements IUserStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = true;

  public auth: ICommonStore<IUserToken> = {
    data: null,
    loading: true,
    error: undefined,
  };

  public builder: ICommonStore<IUserBuilder> = {
    data: null,
    loading: true,
    error: undefined,
  };

  public tester: ICommonStore<ITester> = {
    data: null,
    loading: true,
    error: undefined,
  };

  public requestStatus: number | undefined = undefined;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async getToken(): Promise<void> {
    try {
      runInAction(() => {
        this.auth.data = null;
        this.auth.loading = true;
        this.auth.error = undefined;
      });

      const localToken = this.rootStore.config.localToken;
      if (typeof localToken === 'string' && localToken.length > 0) {
        runInAction(() => {
          this.auth.data = {
            ...this.auth.data,
            token: localToken,
          };
          this.auth.loading = false;
        });

        await this.getS3Token();

        return;
      }

      if (window.unione) {
        const unioneToken = await window.unione.getUnioneToken();

        if (typeof unioneToken !== 'string' || unioneToken === 'null' || unioneToken.length === 0) {
          runInAction(() => {
            this.auth.data = null;
            this.auth.loading = false;
            this.loading = false;
          });
          return;
        }

        await this.getS3Token();

        runInAction(() => {
          this.auth.data = {
            ...this.auth.data,
            token: unioneToken,
          };
          this.auth.loading = false;
        });
      }
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.auth.error = getErrorMessage(error);
        this.auth.loading = false;
      });
    }
  }

  public getS3Token = async(): Promise<void> => {
    try {
      const localS3Token = this.rootStore.config.localS3Token;
      if (typeof localS3Token === 'string' && localS3Token.length > 0) {
        runInAction(() => {
          this.auth.data = {
            ...this.auth.data,
            s3Token: localS3Token,
          };
        });

        return;
      }

      if (window.unione) {
        const unioneS3Token = await window.unione.getS3Token();

        if (typeof unioneS3Token !== 'string' || unioneS3Token === 'null' || unioneS3Token.length === 0) {
          runInAction(() => {
            this.auth.data = {
              ...this.auth.data,
              s3Token: null,
            };
          });
          return;
        }

        runInAction(() => {
          this.auth.data = {
            ...this.auth.data,
            s3Token: unioneS3Token,
          };
        });
      }
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.auth.error = getErrorMessage(error);
      });
    }
  };

  public getUser = async(courseId?: string): Promise<void> => {
    runInAction(() => {
      this.loading = true;
    });

    if (courseId) {
      await this.getBuilderTester(courseId);
    }
    else {
      await this.getBuilderUser();
    }

    runInAction(() => {
      this.loading = false;
    });
  };

  public getBuilderUser = async(): Promise<void> => {
    if (!this.auth.data || !this.auth.data.token) {
      console.error('Unione auth token not found');
      this.builder.loading = false;
      return;
    }

    try {
      runInAction(() => {
        this.builder.data = null;
        this.builder.loading = true;
        this.builder.error = undefined;
      });

      const response = await sdkGetUser({
        baseURL: this.rootStore.config.builderAPI,
        token: this.auth.data.token,
      });

      runInAction(() => {
        this.builder.data = response;
        this.builder.loading = false;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.builder.error = getErrorMessage(error);
        this.builder.loading = false;
      });
    }
  };

  public async getBuilderTester(courseId: string): Promise<void> {
    if (!this.auth.data?.token) {
      console.error('Unione auth token not found');

      this.tester.loading = false;
      return;
    }

    try {
      runInAction(() => {
        this.tester.data = null;
        this.tester.loading = true;
        this.tester.error = undefined;
        this.requestStatus = undefined;
      });

      const response = await sdkAssignTester({
        baseURL: this.rootStore.config.builderAPI,
        token: this.auth.data.token,
        data: { courseId },
      });

      await this.getBuilderUser();

      runInAction(() => {
        this.tester.data = response;
        this.tester.loading = false;
      });

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.ASSIGN_TESTER,
        request: {
          'user-id': response.id,
          'course-id': courseId,
          type: 'link',
        },
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        if (error instanceof AxiosError) {
          this.requestStatus = error.response.status;
        }
        this.tester.error = getErrorMessage(error);
        this.tester.loading = false;
      });

      await this.getBuilderUser();

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.ASSIGN_TESTER,
        request: {
          type: 'link',
          'course-id': courseId,
        },
        error,
      });
    }
  }

  public redirectToResumeService = async(): Promise<void> => {
    try {
      const data = await sdkGetAuthLink({
        baseURL: this.rootStore.config.flamingoAPI,
        token: this.auth.data.token,
      });

      document.location = data.url;
    }
    catch (err) {
      const error = err as AxiosError<{ message?: string }>;
      const errorMessage = error.response.data?.message || 'Что-то пошло не так';

      runInAction(() => {
        this.requestStatus = error.response.status;
        this.tester.error = errorMessage;
      });

      toast({
        text: errorMessage,
        type: 'error',
        loadingBar: true,
      });
    }
  };

  public updateAgreements = async(data: IAgreements): Promise<void> => {
    try {
      runInAction(() => {
        this.builder.error = undefined;
      });

      await sdkUpdateAgreements({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.users.auth.data.token,
        data,
      });

      toast({
        text: 'Соглашение обновлено',
        type: 'success',
      });

      this.builder.data.agreements = data;
    }
    catch (error) {
      toast({
        text: getErrorMessage(error),
        type: 'error',
      });

      runInAction(() => {
        this.builder.error = getErrorMessage(error);
      });
    }
  };

  public clearRequestStatus = (): void => {
    this.requestStatus = undefined;
  };

}
