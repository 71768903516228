import React, { FC, SVGProps } from 'react';

export const Timer: FC<SVGProps<SVGSVGElement>> = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons / Basic">
      <circle id="Ellipse 55" cx="14" cy="14.0002" r="9.8" stroke="#F7B055" strokeWidth="1.5" />
      <path id="Ellipse 56" d="M19.6002 14.0004C17.5 14.0004 17.093 14.0004 14.0002 14.0004C14.0002 10.9076 13.9998 11.2004 14.0002 8.40039C17.093 8.40039 19.6002 10.9076 19.6002 14.0004Z" stroke="#F7B055" strokeWidth="1.5" />
    </g>
  </svg>
);
