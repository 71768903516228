import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    modal: {
      maxWidth: '580px !important',
    },
    agreement: {
      color: theme.palette.gray.contrastText,
      backgroundColor: 'transparent !important',
      padding: '3px 10px !important',
    },
    closeIcon: {
      color: theme.palette.gray.contrastText,
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.gloomyBlue.main,
      },
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'end',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    title: {
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '36px',
      color: theme.palette.gloomyBlue.main,
      fontFamily: theme.fontFamily.monserrat,
    },
    text: {
      color: theme.palette.gloomyBlue.main,
    },
    content: {
      padding: '0 32px',
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
    },
    agreementWrapper: {
      display: 'flex',
      gap: 12,
      alignItems: 'start',
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.secondaryText.main,
    },
    footer: {
      padding: '8px 32px 32px',
    },
    button: {
      borderRadius: '12px !important',
    },
    checkbox: {
      width: 20,
      height: 20,
      padding: 2,
      borderRadius: 4,
    },
  }),
  { name: 'QRCodeModal' },
);
