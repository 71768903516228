import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    modal: {
      padding: '24px 40px 32px',
      width: 440,
      background: '#fff',
      borderRadius: 8,
      borderTop: {
        width: 4,
      },
      rowGap: '0 !important',
    },
    closeIcon: {
      cursor: 'pointer',
      marginLeft: 'auto',
      color: theme.palette.secondaryText.main,
      width: 30,
      height: 30,
    },
    modalIcon: {
      margin: {
        top: 0,
        bottom: 32,
        left: 'auto',
        right: 'auto',
      },
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      margin: {
        bottom: 40,
      },
      textAlign: 'center',
    },
    modalTitle: {
      color: theme.palette.gloomyBlue.main,
      fontFamily: theme.fontFamily.monserrat,
      font: {
        size: 24,
        weight: 600,
        style: 'normal',
      },
      lineHeight: '28px',
      textAlign: 'center',
    },
    modalSubtitle: {
      color: theme.palette.secondaryText.main,
      font: {
        size: 16,
        weight: 400,
        style: 'normal',
        family: 'IBM Plex Sans',
      },
      lineHeight: '20px',
      textAlign: 'center',
    },
    modalButton: {
      font: {
        size: 16,
        weight: 500,
        style: 'normal',
        family: 'IBM Plex Sans',
      },
      lineHeight: '20px',
      height: 44,
      padding: 0,
    },
    '@keyframes fadeOut': {
      from: {
        opacity: 0,
        transform: 'scale(0.85)',
      },
      to: {
        opacity: 1,
        transform: 'scale(1)',
      },
    },
    '@media only screen and (max-device-width: 1024px)': {
      modal: {
        border: 'none',
        borderRadius: 0,
        minHeight: '100%',
        minWidth: '100%',
        padding: 24,
        '& svg:first-child': {
          marginBottom: 'auto',
          background: '#F5F5F7',
          borderRadius: '50%',
        },
        '& button': {
          marginTop: 'auto',
        },
      },
    },
  }),
  { name: 'Block' },
);
