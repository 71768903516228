import { createUseStyles } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/theme/createUseStyles';

export const useInsufficientDataModalStyles = createUseStyles(
  (theme) => ({
    modal: {
      maxWidth: 595,
    },
    flex: {},
    title: {
      color: theme.palette.gloomyBlue.main,
      font: {
        size: 28,
        weight: 700,
        family: theme.fontFamily.monserrat,
      },
      lineHeight: '40px',
    },
    description: {
      color: theme.palette.gloomyBlue.main,
      marginBottom: '36px',
    },
    icon: {
      background: theme.palette.error.main,
      color: theme.background.paper,
      borderRadius: 12,
      width: '64px',
      height: '64px',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '32px',
      '& > svg': {
        width: 33,
        height: 33,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      gap: 12,
      marginBottom: 10,
    },
    '@media (max-width: 540px)': {
      modal: {
        height: '100%',
        maxWidth: 'none',
      },
      flex: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      },
    },
    '@media (max-width: 500px)': {
      icon: {
        borderRadius: 6,
        width: '45px',
        height: '45px',
        marginBottom: '16px',
        '& > svg': {
          width: 20,
          height: 20,
        },
      },
      title: {
        font: {
          size: 24,
        },
        lineHeight: '40px',
      },
      description: {
        marginBottom: '18px',
      },
    },
  }),
  { name: 'InsufficientDataModal' },
);
