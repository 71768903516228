export class BrowserRoute {

  public static courses = '/courses';
  public static courseOldRoute = (id: string = ':id'): string => `/course/${id}`;
  public static course = (id: string = ':id'): string => `/courses/${id}`;
  public static courseStage = (id: string = ':id', stage: string = ':stage'): string => `/courses/${id}/${stage}`;
  public static courseStageAnswers = (id: string = ':id', stage: string = ':stage'): string => `/courses/${id}/${stage}/answers`;
  public static proctoring = '/proctoring';
  public static certificates = '/certificates';
  public static demoAssessment = '/demo-assessment';
  public static demoAssessmentStage = (stage: string = ':demoStage'): string => `/demo-assessment/${stage}`;
  public static demoAssessmentStageAnswers = (stage: string = ':demoStage'): string => `/demo-assessment/${stage}/answers`;

  public static notFound = '/not-found';

}
