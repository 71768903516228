import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      padding: 32,
      width: '100%',
      maxHeight: 620,
      backgroundColor: 'white',
      borderRadius: 16,
    },
    empty: {
      display: 'flex',
      alignItems: 'center',
      gap: 32,
      flexDirection: 'column',
      maxWidth: 744,
    },
    image: {
      maxWidth: 444,
      maxHeight: 280,
      '& > img': {
        margin: '0 auto',
        maxWidth: '80%',
        maxHeight: '100%',
      },
    },
    description: {
      textAlign: 'center',
      color: '#6F7C98',
      font: {
        family: theme.fontFamily.monserrat,
        size: 18,
        weight: 500,
      },
      lineHeight: '24px',
    },
    '@media (max-width: 640px)': {
      root: {
        padding: 20,
      },
      empty: {
        gap: 16,
      },
      description: {
        fontSize: 16,
        lineHeight: '20px',
      },
    },
  }),
  { name: 'EmptyCard' },
);
