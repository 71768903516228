import { QuestionType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { ITestingAnswer, ITestingQuestionDto } from '../../../models/entities/testing.models';

export const getQuestionAnswer = (question: ITestingQuestionDto): ITestingAnswer[] => {
  const userAnswerId = question['user-answer-id'];

  if (question.type === QuestionType.open) {
    const userAnswer = question['user-answer'];

    return userAnswer ? [{ 'user-answer': userAnswer }] : [];
  }

  if (!userAnswerId) {
    return [];
  }

  if (Array.isArray(userAnswerId) && userAnswerId.every((id) => !id)) {
    return [];
  }

  if (question.type === QuestionType.tree) {
    const answers = Object.entries(userAnswerId).map(([key, value]) => {
      const currentQuestion = question.steps
        .find((el) => el.order === Number(key))
        .questions.find((quest) => quest.id === value);
      return { 'answer-id': value, answer: currentQuestion?.question, pictures: [] };
    });
    return answers;
  }

  const selectedAnswerIds = (
    !Array.isArray(userAnswerId) && typeof userAnswerId === 'object' ? Object.values(userAnswerId) : userAnswerId);
  return question.answers?.filter((el) => selectedAnswerIds.includes(el['answer-id']));
};
