import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';

export const RedirectToCourse: FC = () => {
  const { id } = useParams();

  const courseNewRoute = BrowserRoute.course(id);

  return <Navigate to={courseNewRoute} />;
};
