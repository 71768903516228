export enum LOGGER_ACTION {
  CLICK_START_TESTING = 'click_start_testing',
  CLICK_CONTINUE_TESTING = 'click_continue_testing',
  CLICK_WATCH_RESULT = 'click_watch_result',
  CLICK_VARIANT_ANSWER = 'click_variant_answer',
  CLICK_NEXT_ANSWER = 'click_next_answer',
  CLICK_PREV_ANSWER = 'click_prev_answer',
  CLICK_CLOSE_TESTING = 'click_close_testing',
  CLICK_FINISH_TESTING = 'click_finish_testing',
  CLICK_OPEN_PICTURE_TASK = 'click_open_picture_task',
  CLICK_OPEN_PICTURE_ANSWER = 'click_open_picture_answer',
  CLICK_NUMBER_TASK = 'click_number_task',
  CLICK_BUTTON_FINISH_MODAL = 'click_button_modal',
  CLICK_BUTTON_CLOSE_MODAL = 'click_button_close_modal',
  ACTIVATED_ERROR_BOUNDARY = 'activated_error_boundary',
  ASSIGN_TESTER = 'assign_tester',
  GET_PROCTO_TOKEN = 'get_procto_token',
  EXTERNAL_PROCTO_INIT = 'external_procto_init',

  ERROR_START_TESTING = 'error_start_testing',
  ERROR_CONTINUE_TESTING = 'error_continue_testing',
  ERROR_FINISH_TESTING = 'error_finish_testing',
  ERROR_WATCH_RESULT = 'error_watch_result',
  ERROR_CLOSE_TESTING = 'error_close_testing',
}
