import { IRootStore } from '../../models/stores/root.store';
import {
  ITestingStore,
  ITestingCoursesStore,
  ITestingResultsStore,
  ITestingPassedTestStore,
  ITestingUserTestStore,
  ITestingUpdateQuestionStore,
  ITestingTestInfoStore,
  ITestingSessionStore,
} from '../../models/stores/testing.store';
import { TestingCoursesStore } from './testing-courses.store';
import { TestingPassedTestStore } from './testing-passed';
import { TestingResultsStore } from './testing-results';
import { TestingSessionStore } from './testing-session';
import { TestingTestInfoStore } from './testing-test-info';
import { TestingUpdateQuestionStore } from './testing-update-question';
import { TestingUserTestStore } from './testing-user-test';

export class TestingStore implements ITestingStore {

  public readonly rootStore: IRootStore;
  public courses: ITestingCoursesStore;
  public results: ITestingResultsStore;
  public passedTest: ITestingPassedTestStore;
  public userTest: ITestingUserTestStore;
  public testInfo: ITestingTestInfoStore;
  public updateQuestion: ITestingUpdateQuestionStore;
  public testingSession: ITestingSessionStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    this.courses = new TestingCoursesStore(rootStore);
    this.results = new TestingResultsStore(rootStore);
    this.passedTest = new TestingPassedTestStore(rootStore);
    this.userTest = new TestingUserTestStore(rootStore);
    this.testInfo = new TestingTestInfoStore(rootStore);
    this.updateQuestion = new TestingUpdateQuestionStore(rootStore);
    this.testingSession = new TestingSessionStore(rootStore);
  }

}
