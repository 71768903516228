import dayjs from 'dayjs';
import {
  ITestInfo,
  ITestingAnswer,
  ITestingQuestion,
  ITestingQuestionDto,
  ITestingTest,
  ITestingTestDto,
} from '../models/entities/testing.models';

export class TestingMappers {

  public static getPassedTest(item: ITestingTest<string>): ITestingTestDto {
    const questions = item.questions.map((question) => this.getQuestionType(question));

    return ({ ...item, questions, 'attempt-time': new Date(item['attempt-time']) });
  }

  public static getUserTest(item: ITestingTest<string>, now: number): ITestingTestDto {
    const attemptStartDate = item['attempt-time']; // Дата начала попытки
    const testDuration = item['test-duration']; // Длительность этапа в минутах
    const attemptEndDate = dayjs(attemptStartDate).add(testDuration, 'minute'); // Дата завершения попытки
    const attemptEndDateInMs = attemptEndDate.valueOf();
    const timeLeft = attemptEndDateInMs - now; // Оставшееся время в милисекундах

    const questions = item.questions.map((question) => this.getQuestionType(question));

    return ({
      ...item,
      questions,
      'attempt-time': new Date(item['attempt-time']),
      timeLeft: timeLeft / 1000,
    });
  }

  public static getTestInfo(item: ITestInfo<string>): ITestInfo {
    return ({ ...item, 'attempt-time': new Date(item['attempt-time']) });
  }

  private static getQuestionType(question: ITestingQuestion): ITestingQuestionDto {
    const type = Object.entries(question.type).reduce((acc, [key, value]) => {
      const answers = this.getClearedAnswers(value.answers);

      return { ...value, answers, type: key };
    }, {});

    return { ...question, ...type } as unknown as ITestingQuestionDto;
  }

  private static getClearedAnswers(answers: ITestingAnswer[]): ITestingAnswer[] {
    return answers?.map((each) => {
      const answer = each.answer?.replace(/(\r\n|\n|\r)/gm, '') || '';

      return { ...each, answer };
    });
  }

}
