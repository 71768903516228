/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip, toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { STAGE_STATUSES, ACTIVE_COURSES } from '../../../constants/builder-course.constants';
import { BrowserRoute } from '../../../routes/browser.routes';
import { getErrorMessage } from '../../../shared/error-message';
import { useTimer } from '../../../shared/use-timer';
import { useAppStore } from '../../../stores/context.store';
import { ProctoringIcon } from '../icons/proctoring';
import { AttentionModal } from './components/atention-modal/atention-modal';
import { InsufficientDataModal } from './components/insufficient-data-modal/insufficient-data-modal';
import { ITestingCardProps } from './course-testing-card.models';
import { useStyles } from './use-styles';

export const TestingCard: FC<ITestingCardProps> = observer(({ stage, demoMode }) => {
  const classes = useStyles();
  const { testing, builderCourse } = useAppStore();
  const navigate = useNavigate();
  const { isOpen, close, open } = useModalState();

  const { data } = builderCourse;
  const { loading, isInsufficientDataModalOpen } = testing.userTest;
  const { initSession, resetSessionStatus, session } = testing.testingSession;

  const customStage = useMemo(() => {
    if (!demoMode) {
      return stage;
    }
    if (sessionStorage.getItem('userTest')) {
      const test = stage.order === 1 ? STAGE_STATUSES.completed : STAGE_STATUSES.notPassed;
      return { ...stage, ...test };
    }

    return stage;
  }, [demoMode, stage]);

  const isDisabled = useMemo(
    () => customStage.disabled || loading || (!data?._id && !demoMode),
    [customStage.disabled, loading, data?._id, demoMode],
  );

  const handleClickButton = (): void => {
    try {
      if (!demoMode && stage.isActive) {
        initSession(stage);
      }

      if (demoMode) {
        const demoPath = sessionStorage.getItem('userTest')
          ? BrowserRoute.demoAssessmentStageAnswers(stage.href)
          : BrowserRoute.demoAssessmentStage(stage.href);
        const path = demoMode ? demoPath : stage.href;
        navigate(path);
      }
      if (stage.status === 'completed') {
        navigate(stage.href);
      }
    }
    catch (error) {
      toast({
        type: 'error',
        text: getErrorMessage(error),
      });
    }
  };

  const stageEndDate = useMemo((): number => {
    const currentDate = dayjs();
    const targetDate = dayjs(stage.end_date);
    const differenceInMilliseconds = targetDate.diff(currentDate);
    const differenceInSeconds = dayjs.duration(differenceInMilliseconds).asSeconds();

    if (stage.timeLeft > differenceInSeconds) {
      return differenceInSeconds - 60;
    }

    return stage.timeLeft;
  }, [stage.timeLeft]);

  const { timer } = useTimer({
    timeLeft: stageEndDate,
    finish: () => {
      localStorage.removeItem(ACTIVE_COURSES);
      builderCourse.refetch();
    },
  });

  useEffect(() => {
    if (session.stage?.order === stage.order) {
      if (session.data) {
        resetSessionStatus();
        navigate(session.stage.href);
      }

      if (!session.data && typeof session.data === 'boolean') {
        open();
      }
    }
  }, [session.data, session.stage]);

  return (
    <Paper className={classes.stage}>
      <div className={classes.stageHeader}>
        <Chip label={stage.name} customColor={stage.color} />
        {stage.is_procto && (
          <Tooltip content="В оценке применяется прокторинг" className={classes.procto}>
            <ProctoringIcon data-tour="introduction-step-7" className={classes.secondaryText} />
          </Tooltip>
        )}
      </div>
      <div data-tour="introduction-step-6" className={cn(classes.secondaryText, classes.stageBody)}>
        <p>{customStage.interval}</p>
        {timer ? <p>Осталось времени {timer}</p> : <p>{customStage.statusTitle}</p>}
      </div>
      <Button
        data-tour="introduction-step-8"
        onClick={handleClickButton}
        size={customStage.isCompleted ? 'none' : 'sm'}
        variantStyle={customStage.isCompleted ? 'standard' : undefined}
        disabled={isDisabled}
        className={classes.btn}
      >
        {customStage.btnLabel}
      </Button>

      <AttentionModal onClose={close} open={isOpen} />
      <InsufficientDataModal open={isInsufficientDataModalOpen} />
    </Paper>
  );
});
