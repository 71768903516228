import { FC } from 'react';
import { getLevelResultInfo } from '../../../mappers/utils';
import { IChartCustomTooltip } from './testing.result.models';
import { useCustomTooltipStyles } from './use-styles';

export const ChartCustomTooltip: FC<IChartCustomTooltip> = (props) => {
  const classes = useCustomTooltipStyles();

  if (!props.active || !props.payload) {
    return null;
  }

  return (
    <div className={classes.tooltip}>
      <h2 className={classes.title}>{props.title}</h2>
      {props.payload.map((el, index) => (
        <p key={index} style={{ color: el.color as string }}>
          <span>{`${el.name}: ${getLevelResultInfo(el.value as number, el.grade)?.levelLabel}`}</span>
        </p>
      ))}
    </div>
  );
};
