import { FC, ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TestType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { ITag } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models/tags.models';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { EditorPreview } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/editor-preview';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { HttpCodes } from '../../../models/requests/http.models';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useTabVisibility } from '../../../shared/use-tab-visibility';
import { useAppStore } from '../../../stores/context.store';
import { BackLink } from '../../components/back-link';
import { Block } from '../../components/block';
import { CourseModal } from '../../components/course-modal';
import { TestingCard } from '../../components/course-testing-card';
import { TestingResultModal } from '../../components/testing-result-modal';
import { CircularProgress } from '../../lab/circular_progress';
import { NotFound } from '../not-found';
import { IExpertiseChip } from './course.models';
import { useStyles } from './use-styles';

export const CoursePage: FC = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const appStore = useAppStore();
  const classes = useStyles();
  const { loading, error, data, fetch, fieldsDisplay, model, timeToPassTest } = appStore.builderCourse;

  useEffect(() => {
    fetch({ courseId: id });
  }, [id, fetch]);

  useTabVisibility(() => {
    fetch({ courseId: id });
  });

  const onCloseHandler = (): void => navigate(BrowserRoute.courses);

  if (appStore.testing.userTest.loading) {
    return (
      <div className={classes.loaderWrapper}>
        <CircularProgress width={48} height={48} />
        <div className={classes.loaderLabel}>Выполняется проверка прокторинга</div>
      </div>
    );
  }

  if (typeof data?.isActive === 'boolean' && !data?.isActive) {
    return (
      <CourseModal
        defaultOpen
        onClose={onCloseHandler}
        onConfirm={onCloseHandler}
        title={<>Извините, у&nbsp;вас&nbsp;нет доступа к&nbsp;этой&nbsp;оценочной сессии</>}
        subtitle={<>Обратитесь&nbsp;к ответственному в&nbsp;вашей&nbsp;организации</>}
      />
    );
  }

  if (error?.includes(HttpCodes.notFound.toString())) {
    return <NotFound />;
  }

  return (
    <Block loading={loading} error={error} data={data}>
      {(course): ReactElement => {
        const expertiseLabel = model.test_options.test_type === TestType.sphere ? 'Сферы' : 'Компетенции';
        const expertiseList: IExpertiseChip[]
          = model.test_options.test_type === TestType.sphere ? course.spheres : course.competencies;
        const courseTags = course.testing.reduce<ITag[]>((acc, testingItem) => {
          if (testingItem.tags?.length > 0) {
            acc.push(...testingItem.tags);
          }
          return acc;
        }, []);

        return (
          <div className={classes.root}>
            <BackLink to={BrowserRoute.courses} label="Назад к списку оценочных сессий" />
            <Paper>
              <div className={classes.orgName}>{course.organization.name}</div>
              <div className={classes.content}>
                <div className={classes.courseInfo}>
                  <div className={classes.description}>
                    {fieldsDisplay.show_qualification_name && course.qualification?.value && (
                      <p className={classes.qualification}>{course.qualification.value}</p>
                    )}
                    <h1 className={classes.title}>{course.title}</h1>
                    {fieldsDisplay.show_description && (
                      <EditorPreview className={classes.desc} children={course.desc} />
                    )}
                  </div>
                  {(fieldsDisplay.show_academic_hours || fieldsDisplay.show_academic_hours || timeToPassTest) && (
                    <div className={classes.infoCardWrapper}>
                      {fieldsDisplay.show_academic_hours && (
                        <div className={classes.infoCard}>
                          <div className={classes.secondaryText}>академических часов</div>
                          <div className={classes.infoTitle}>{course.academic.hours}</div>
                        </div>
                      )}
                      {fieldsDisplay.show_academic_months && (
                        <div className={classes.infoCard}>
                          <div className={classes.secondaryText}>месяцев обучения</div>
                          <div className={classes.infoTitle}>{course.academic.month}</div>
                        </div>
                      )}
                      {timeToPassTest && (
                        <div className={classes.infoCard}>
                          <div className={classes.secondaryText}>примерное время прохождения</div>
                          <div className={cn(classes.infoTitle, classes.timeInfo)}>{timeToPassTest}</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <hr />
                <div className={classes.courseBindings}>
                  <div className={classes.courseBinding}>
                    <div className={classes.secondaryText}>{expertiseLabel}</div>
                    <div className={classes.competencies}>
                      {expertiseList.map((expertise) => (
                        <Chip className={classes.bindingName} key={expertise._id} label={expertise.title} />
                      ))}
                    </div>
                  </div>
                  {Boolean(courseTags.length) && (
                    <div className={classes.courseBinding}>
                      <div className={classes.secondaryText}>Теги</div>
                      <div className={classes.competencies}>
                        {courseTags.map((tag) => (
                          <Chip className={classes.bindingName} key={tag._id} label={tag.title} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Paper>
            <div className={classes.stages}>
              <div className={cn(classes.stagesWrapper, 'scrollbar')}>
                {course.stages.map((stage) => (
                  <TestingCard key={stage.order} stage={stage} />
                ))}
              </div>
              <TestingResultModal />
            </div>
          </div>
        );
      }}
    </Block>
  );
});
