import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      padding: '20px 40px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    questionWrapper: {
      paddingRight: 40,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      height: 'fit-content',
    },
    chip: {
      flexShrink: 0,
    },
    answersWrapper: {
      paddingLeft: 40,
      borderLeft: '1px solid #E4E7F2',
      display: 'flex',
      flexDirection: 'column',
      gap: 28,
    },
    answersLabel: {
      color: '#B7B9C0',
      fontSize: 14,
      lineHeight: '20px',
    },
    editor: {
      color: '#1E253B',
      padding: 0,
      wordBreak: 'break-word',
      '& > hr': {
        margin: '16px 0',
      },
    },
    question: {
      font: {
        size: 18,
        weight: 500,
      },
      lineHeight: '24px',
      overflowY: 'unset',
    },
    image: {
      maxWidth: 350,
      objectFit: 'cover',
      width: '100%',
      cursor: 'pointer',
      marginTop: 8,
      borderRadius: 8,
    },
    '@media (max-width: 1280px)': {
      root: {
        padding: '20px 32px',
      },
      questionWrapper: {
        paddingRight: 24,
      },
      answersWrapper: {
        paddingLeft: 24,
      },
    },
    '@media (max-width: 768px)': {
      root: {
        padding: 24,
        gridTemplateColumns: '1fr',
      },
      questionWrapper: {
        paddingRight: 0,
        paddingBottom: 16,
        gap: 8,
      },
      answersWrapper: {
        borderLeft: 'none',
        borderTop: '1px solid #E4E7F2',
        paddingLeft: 0,
        paddingTop: 16,
        gap: 8,
      },
      question: {
        fontFamily: theme.fontFamily.monserrat,
      },
    },
  }),
  { name: 'AnswerPreview' },
);
