import { FC, SVGProps } from 'react';

export const AlarmIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.0588 47.0998V42.7527C62.1605 41.669 57.1545 41.1409 52.1367 41.1783C50.8127 41.1783 49.5086 41.2278 48.2244 41.3169C46.2063 41.4509 44.1963 41.6855 42.2018 42.02C41.6742 42.1091 41.1466 42.1982 40.629 42.2972C38.7741 42.6471 36.9756 43.0695 35.2335 43.5646C33.1098 44.162 31.0724 44.8419 29.1213 45.6044C27.2896 46.3107 25.5376 47.0698 23.8652 47.8818C20.9026 49.3257 18.0393 50.963 15.2941 52.7831V55.8398C19.5958 65.1817 29.0402 71.6667 39.9992 71.6667C54.1297 71.6667 65.742 60.8853 67.0588 47.0998Z"
      fill="currentColor"
      fillOpacity="0.12"
    />
    <path
      d="M40 31.2581V44.4828L48.8164 53.2992"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.1832 44.4827C67.1832 29.4694 55.0125 17.2987 39.9992 17.2987C24.9859 17.2987 12.8152 29.4694 12.8152 44.4827C12.8152 59.496 24.9859 71.6667 39.9992 71.6667C55.0125 71.6667 67.1832 59.496 67.1832 44.4827Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M8.1673 18.9974C11.6856 14.6084 16.0929 10.9627 21.116 8.33334M71.8323 18.9974C68.3139 14.6084 63.9067 10.9627 58.8836 8.33334"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
