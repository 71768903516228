import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      height: 100,
      width: 100,
      borderRadius: '50%',
      position: 'fixed',
      zIndex: 9999,
      cursor: 'pointer',
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.disabled.main,
    },
    loader: {
      position: 'absolute',
      width: 25,
      height: 25,
    },
    video: {
      height: 90,
      width: 90,
      borderRadius: '50%',
      objectFit: 'cover',
    },
    warning: {
      background: theme.palette.warning.main,
    },
    error: {
      background: theme.palette.error.main,
    },
    errorMessage: {
      background: theme.palette.error.main,
      borderRadius: 3,
      boxShadow: '0 0 3px 0 rgba(0,0,0,.5)',
      cursor: 'pointer',
      fontWeight: 700,
      lineHeight: '1em',
      maxWidth: '50vw',
      padding: 10,
      position: 'absolute',
      textShadow: '0 0 20px hsla(0,0%,100%,.5)',
      top: '50%',
      left: '110%',
      transform: 'perspective(1px) translateY(-50%)',
      userSelect: 'none',
      visibility: 'visible',
      width: 'max-content',

      '&:after': {
        borderRightColor: theme.palette.error.main,
        border: '5px solid transparent',
        content: '" "',
        marginTop: -5,
        position: 'absolute',
        right: '100%',
        top: '50%',
      },
    },
  }),
  { name: 'VideoPreview' },
);
