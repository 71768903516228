import { IFieldsDisplay } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models/builder-course-model.models';
import { TestingStageStatus } from '../models/entities/builder-course.models';
import { IStageStatusInfo } from '../models/stores/builder.course.store';

const FIRST_ANSWER_ASCII = 1040;

export const ANSWER_VARIANTS: string[] = Array.from(
  { length: 32 },
  (_, index) => String.fromCharCode(FIRST_ANSWER_ASCII + index),
);

export const STAGE_STATUSES: {
  [key in Exclude<TestingStageStatus, 'notStarted'>]: IStageStatusInfo;
} = {
  completed: {
    statusTitle: 'Завершено',
    btnLabel: 'Смотреть ответы',
    isCompleted: true,
    status: TestingStageStatus.completed,
  },
  proceed: {
    statusTitle: 'Продолжить оценку',
    btnLabel: 'Продолжить',
    isActive: true,
    status: TestingStageStatus.proceed,
  },
  start: {
    btnLabel: 'Начать оценку',
    isActive: true,
    status: TestingStageStatus.start,
  },
  beginning: {
    btnLabel: 'Оценка не начата',
    disabled: true,
    status: TestingStageStatus.beginning,
  },
  notPassed: {
    statusTitle: 'Вы не прошли оценку',
    btnLabel: 'Не пройдено',
    disabled: true,
    status: TestingStageStatus.notPassed,
  },
  notAccess: {
    statusTitle: 'Вы не прошли прошлую оценку',
    btnLabel: 'Нет доступа',
    disabled: true,
    status: TestingStageStatus.notAccess,
  },
  archived: {
    statusTitle: 'Оценка недоступна',
    btnLabel: 'Нет доступа',
    disabled: true,
    status: TestingStageStatus.archived,
  },
};

export const STAGE_COLORS = {
  1: 'success',
  2: 'warning',
  3: 'info',
};

export const ACTIVE_COURSES = 'activeCourses';

export const DEFAULT_FIELDS_DISPLAY: IFieldsDisplay = {
  show_qualification_name: true,
  show_academic_hours: true,
  show_academic_months: true,
  show_branch_industry: true,
  show_description: true,
  show_questions: true,
  show_docs: true,
  show_it: true,
};
