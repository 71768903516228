import { makeAutoObservable, runInAction } from 'mobx';
import { TStage } from '../../models/stores/builder.course.store';
import { IRootStore } from '../../models/stores/root.store';
import { ITestingSessionState, ITestingSessionStore } from '../../models/stores/testing.store';
import { getErrorMessage } from '../../shared/error-message';

const HEALTH_CHECK_INTERVAL = 5000;

interface IMessageEventResponseData {
  status: boolean;
  need_fields: boolean;
  errors: [];
  values: string[];
  tm_req: string;
}

export class TestingSessionStore implements ITestingSessionStore {

  public readonly rootStore: IRootStore;

  public session: ITestingSessionState = {
    data: null,
    stage: null,
    socket: null,
    interval: null,
    error: undefined,
    loading: false,
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public initSession = (courseStage: TStage): void => {
    try {
      runInAction(() => {
        this.destroySession();
        this.session.loading = true;
        this.session.stage = courseStage;
      });

      const url = this.rootStore.config.collectorSessionsWssAPI;

      const socket = new WebSocket(url);

      socket.onmessage = (event): void => {
        this.onMessageSession(event);
      };

      socket.onopen = (): void => {
        this.connectSession();
      };

      runInAction(() => {
        this.session.socket = socket;
      });
    }
    catch (error) {
      runInAction(() => {
        this.session.error = getErrorMessage(error);
      });
    }
    finally {
      runInAction(() => {
        this.session.loading = false;
      });
    }
  };

  public destroySession = (): void => {
    try {
      if (this.session.socket) {
        this.session.socket.close();
        clearInterval(this.session.interval);
        this.session.interval = null;
      }

      this.resetSession();
    }
    catch (error) {
      runInAction(() => {
        this.session.error = getErrorMessage(error);
      });
    }
  };

  public resetSession = (): void => {
    this.session.data = null;
    this.session.stage = null;
    this.session.socket = null;
    this.session.interval = null;
    this.session.error = undefined;
    this.session.loading = false;
  };

  public resetSessionStatus = (): void => {
    this.session.data = null;
  };

  public connectSession = (): void => {
    try {
      const requestData = {
        'user-id': this.rootStore.users.builder.data.id,
        'course-id': this.rootStore.builderCourse.data?._id,
        'course-stage': this.session.stage.order,
        'course-stage-id': this.session.stage._id,
        token: this.rootStore.users.auth.data.token,
      };

      this.session.socket.send(JSON.stringify(requestData));

      this.session.interval = setInterval(() => {
        this.session.socket.send('ping');
      }, HEALTH_CHECK_INTERVAL);
    }
    catch (error) {
      runInAction(() => {
        this.session.error = getErrorMessage(error);
      });
    }
  };

  public onMessageSession = (event: MessageEvent): void => {
    if (event.data === 'ping') {
      return;
    }

    try {
      const data: IMessageEventResponseData = JSON.parse(event.data);

      if (data.status) {
        const {
          is_procto: isProcto,
          order: stageOrder,
          _id: stageId,
          status,
          attemptLeft,
          timeLeft,
        } = this.session.stage;

        this.rootStore.testing.userTest.startTest(
          {
            isProcto,
            stageOrder,
            stageId,
            status,
          },
          {
            attemptLeft,
            timeLeft,
          },
        );
      }

      runInAction(() => {
        this.session.data = data.status;
      });
    }
    catch (error) {
      runInAction(() => {
        this.session.error = getErrorMessage(error);
      });
    }
  };

}
