import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    tabs: {
      fontSize: 16,
      lineHeight: '20px',
    },
    tab: {
      overflowY: 'auto',
    },
    modal: {
      minHeight: 400,
      maxHeight: '100vh',
      color: theme.palette.gloomyBlue.main,
      fontSize: 14,
      wordBreak: 'break-word',
      '& .ModalHeader-icon': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
      },
    },
    title: {
      font: {
        size: 20,
        weight: 600,
        family: theme.fontFamily.monserrat,
      },
      lineHeight: '24px',
    },
    subtitle: {
      color: theme.palette.secondaryText.main,
      lineHeight: '20px',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 2,
      overflow: 'hidden',
    },
    bold: {
      fontWeight: 500,
    },
    result: {
      flexGrow: 1,
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.secondaryText.main,
      cursor: 'pointer',
      textAlign: 'center',
      gap: 12,
      padding: {
        top: 20,
        bottom: 20,
        right: 40,
        left: 40,
      },
      marginBottom: 4,

      '& > svg': {
        width: 24,
        height: 24,
      },

      '&:hover': {
        color: '#334155',
      },
    },
    '@media (max-width: 1490px)': {
      result: {
        flexDirection: 'row',
      },
    },
    '@media (max-width: 830px)': {
      modal: {
        height: '100vh',
        padding: 24,
        borderTop: 0,
        borderRadius: 0,
        '& .ModalHeader-root': {
          display: 'flex',
          flexDirection: 'column-reverse',
          rowGap: 8,
        },
        '& .ModalHeader-icon': {
          rowGap: 12,
        },
        '& .ModalHeader-close': {
          position: 'static',
          marginLeft: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: '#F5F5F7',
          color: theme.palette.gloomyBlue.main,
          '& path': {
            strokeWidth: 1,
          },
        },
      },
      title: {
        fontSize: 24,
        lineHeight: '28px',
      },
      tabs: {
        gap: 0,
        '& > div': {
          flexGrow: 1,
          textAlign: 'center',
        },
      },
    },
  }),
  { name: 'TestingResultsModal' },
);

type RuleNames = 'root' | 'container' | 'title' | 'secondaryText' | 'cell' | 'borderTop' | 'mobile' | 'mobileTitle' | 'mobileStage' | 'stage' | 'stageBtn';

interface IUseStyleProps {
  count: number;
}

export const useResultGridStyles = createUseStyles<RuleNames, IUseStyleProps>(
  (theme) => ({
    root: {
      overflow: 'auto',
    },
    container: {
      display: 'grid',
      gridTemplateColumns: (props): string => `repeat(${props.count}, calc((100% - 280px) / ${props.count})) 1fr`,
    },
    title: {
      borderTopWidth: 1,
      borderRightWidth: 1,
    },
    secondaryText: {
      color: theme.palette.secondaryText.main,
    },
    cell: {
      padding: '16px 8px',
    },
    borderTop: {
      borderTopWidth: 1,
    },
    mobile: {
      display: 'none',
      flexDirection: 'column',
      gap: 24,
    },
    mobileTitle: {
      font: {
        size: 18,
        weight: 600,
      },
      lineHeight: '24px',
    },
    mobileStage: {
      display: 'grid',
      gridTemplateColumns: '100px 1fr',
      columnGap: 12,
      rowGap: 8,
      paddingTop: 12,
      paddingBottom: 16,
      borderBottomWidth: 1,
      fontSize: 14,
      lineHeight: '20px',
    },
    stage: {
      color: theme.palette.primary.contrastText,
    },
    stageBtn: {
      padding: '4px 16px',
      borderRadius: '4px',
    },
    '@media (max-width: 830px)': {
      mobile: {
        display: 'flex',
      },
      container: {
        display: 'none',
      },
    },
  }),
  { name: 'ResultModalGrid' },
);

export const useCustomTooltipStyles = createUseStyles(
  (theme) => ({
    tooltip: {
      backgroundColor: theme.background.paper,
      padding: 16,
      borderWidth: 1,
      width: 400,
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'auto',
    },
    title: {
      fontWeight: 500,
      borderBottomWidth: 1,
      marginBottom: 8,
      paddingBottom: 8,
    },
  }),
  { name: 'CustomTooltip' },
);

export const useRadarChartStyles = createUseStyles(
  () => ({
    targetValueLine: {
      transform: 'translate(50%, 45%)',
    },
    chart: {
      '& .recharts-legend-wrapper': {
        maxHeight: '100%',
        overflow: 'auto',
      },
      '& .recharts-legend-item': {
        cursor: 'pointer',

        '&:hover': {
          filter: 'brightness(110%)',
        },
      },
    },
    '@media (max-width: 830px)': {
      chart: {
        '& .recharts-legend-wrapper': {
          maxHeight: '65px',
          overflow: 'auto',
        },
        marginBottom: 10,
      },
    },
  }),
  { name: 'RadarChart' },
);
