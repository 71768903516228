import { createUseStyles } from 'react-jss';

export const MIN_SIDEBAR_WIDTH = 60;
export const DEFAULT_SIDEBAR_WIDTH = 280;
export const MIN_CONTENT_WRAPPER_WIDTH = 1024;

type RuleNames = 'main' | 'sidebar' | 'content' | 'resizeBlock' | 'resizeLine';

interface IUseStyleProps {
  sidebarWidth: number;
}

export const useStyles = createUseStyles<RuleNames, IUseStyleProps>(
  (theme) => ({
    main: {
      display: 'flex',
      flexGrow: 1,
      backgroundColor: theme.background.lightGray,
      width: '100%',
      height: '100%',
    },
    sidebar: {
      backgroundColor: theme.background.paper,
      maxHeight: 'calc(100vh - 64px)',
      minWidth: MIN_SIDEBAR_WIDTH,
      maxWidth: (props): number => props.sidebarWidth,
      overflow: 'auto',
      position: 'sticky',
      top: 64,
      flexBasis: '100%',
    },
    resizeBlock: {
      position: 'sticky',
      top: 64,
      maxHeight: 'calc(100vh - 64px)',
      cursor: 'col-resize',
      userSelect: 'none',
      zIndex: 11,
      '&:hover > div': {
        paddingRight: 5,
      },
      '&:active > div': {
        paddingRight: 5,
      },
    },
    resizeLine: {
      position: 'absolute',
      backgroundColor: theme.palette.lines.main,
      height: '100%',
      paddingRight: 1,
    },
    content: {
      minWidth: MIN_CONTENT_WRAPPER_WIDTH,
      flexGrow: 1,
    },
    '@media (max-width: 1320px)': {
      main: {
        flexDirection: 'column',
      },
      sidebar: {
        width: '100%',
        maxWidth: (): string => 'initial',
      },
      content: {
        minWidth: 'initial',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    '@media (max-width: 640px)': {
      main: {
        padding: 24,
      },
      sidebar: {
        position: 'static',
        borderRadius: 8,
      },
    },
    '@media only screen and (max-device-width: 1024px) and (orientation: landscape)': {
      sidebar: {
        position: 'static',
      },
    },
  }),
  { name: 'main' },
);
