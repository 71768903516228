import { ChangeEventHandler, FC, useState } from 'react';
import { TAnswerIds, UpdateMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { TextArea } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { EditorPreview } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/editor-preview';
import { useDebounceFn } from 'ahooks';
import { ITestingQuestionOpen } from '../../../../../models/entities/testing.models';
import { useStyles } from './use-styles';

interface IQuestionOpenProps {
  question: ITestingQuestionOpen;
  renderPictures(pictures: string[], type: string): JSX.Element;
  handleUpdate(answerId: TAnswerIds, mode?: UpdateMode): void;
}

export const QuestionOpen: FC<IQuestionOpenProps> = ({ question, renderPictures, handleUpdate }) => {
  const { 'user-answer': userAnswer = '', question: title } = question;
  const [answer, setAnswer] = useState(userAnswer);
  const classes = useStyles();

  const { run } = useDebounceFn(
    (val) => {
      handleUpdate(val);
    },
    {
      wait: 500,
    },
  );

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setAnswer(event.target.value);
    run(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div>
        <h2 className={classes.title}>{title}</h2>
        <EditorPreview children={question.description} />
        {renderPictures(question.pictures, 'question')}
      </div>

      <div className={classes.field}>
        <div className={classes.fieldLabel}>Ваш ответ</div>
        <TextArea className={classes.textArea} value={answer} onChange={handleChange} />
      </div>
    </div>
  );
};
