import { FC } from 'react';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/button';
import { CloseIcon } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/icons/close';
import { Modal } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { WarningV2Icon } from '../icons/warning-v2';
import { ICourseModal } from './course.models';
import { useStyles } from './use-styles';


export const CourseModal: FC<ICourseModal> = ({
  defaultOpen,
  modalProps,
  icon,
  content,
  title,
  subtitle,
  button,
  buttonText,
  onConfirm,
  onClose,
}) => {
  const classes = useStyles();
  const modalState = useModalState();

  const onCloseHandler = (): void => {
    modalState.close();

    onClose?.();
  };

  const onConfirmHandler = (): void => {
    modalState.close();

    onConfirm?.();
  };

  return (
    <Modal
      className={classes.modal}
      onClose={onCloseHandler}
      color="orange"
      open={defaultOpen ?? modalState.isOpen}
      {...modalProps}
    >
      <CloseIcon
        onClick={onCloseHandler}
        className={classes.closeIcon}
        width={28}
        height={28}
      />
      {icon || (
        <WarningV2Icon className={classes.modalIcon} />
      )}
      {content || (
        <div className={classes.modalContent}>
          <h3 className={classes.modalTitle}>
            {title || 'Неизвестная ошибка'}
          </h3>
          <p className={classes.modalSubtitle}>
            {subtitle || ''}
          </p>
        </div>
      )}

      {button || (
        <Button
          className={classes.modalButton}
          variant="warning"
          size="sm"
          onClick={onConfirmHandler}
        >
          {buttonText || 'OK'}
        </Button>
      )}
    </Modal>
  );
};
