import { FC, SVGProps } from 'react';

export const CloseV2Icon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="icons/x-01">
      <path
        id="Icon"
        d="M15.8334 4.16699L4.16669 15.8337M15.8334 15.8337L4.16669 4.16699"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
);
