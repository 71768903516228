import { FC, ReactElement, useEffect } from 'react';
import { DisplayResults } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Modal, ModalHeader } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { Tab, TabPanel, Tabs, useTabs } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/tabs';
import { observer } from 'mobx-react';
import {
  GRID_COMPETENCY_HEADER_LABELS,
  GRID_SPHERE_HEADER_LABELS,
} from '../../../constants/testing-result-modal.constants';
import { useAppStore } from '../../../stores/context.store';
import { Block } from '../block';
import { ChartIcon } from '../icons/chart';
import { ResultGrid } from './result-grid';
import { ResultRadarChart } from './result-radar-chart';
import { useStyles } from './use-styles';

export const TestingResultModal: FC = observer(() => {
  const classes = useStyles();
  const modal = useModalState();
  const appStore = useAppStore();

  const { loading, error, data } = appStore.testing.results.results;
  const { display_results } = appStore.builderCourse.model;
  const displayOnlyBySphere = display_results === DisplayResults.sphere;
  const displayOnlyByCompetency = display_results === DisplayResults.competency;
  const tabs = useTabs(displayOnlyBySphere ? 'spheres' : 'competencies');

  useEffect(() => {
    if (modal.isOpen) {
      appStore.testing.results.loadResults();
    }
  }, [appStore.testing.results, modal.isOpen]);

  return (
    <>
      <Paper className={classes.result} onClick={modal.open}>
        <ChartIcon />
        <span>Смотреть результаты</span>
      </Paper>

      <Modal open={modal.isOpen} size="xl" className={classes.modal} color="blue">
        <ModalHeader onClose={modal.close}>
          <h2 className={classes.title}>Результаты прохождения оценочной сессии</h2>
          <p className={classes.subtitle}>{data?.courseTitle}</p>
        </ModalHeader>

        <Tabs value={tabs.value} onChange={tabs.onChange} className={classes.tabs}>
          {!displayOnlyBySphere ? <Tab label="Компетенции" value="competencies" /> : <></>}
          {!displayOnlyByCompetency ? <Tab label="Сферы" value="spheres" /> : <></>}
          <Tab label="Диаграмма" value="diagram" />
        </Tabs>

        <Block loading={loading} error={error} data={data}>
          {(results): ReactElement => (
            <>
              <TabPanel value="competencies" current={tabs.value} className={classes.tab}>
                <ResultGrid data={results.competencies} columns={GRID_COMPETENCY_HEADER_LABELS} />
              </TabPanel>

              <TabPanel value="spheres" current={tabs.value} className={classes.tab}>
                <ResultGrid data={results.spheres} columns={GRID_SPHERE_HEADER_LABELS} />
              </TabPanel>

              <TabPanel value="diagram" current={tabs.value} className={classes.tab}>
                <ResultRadarChart charts={results.charts} stages={results.stages} displayResults={display_results} />
                {results.charts.map((chart) => (
                  <div key={chart.id} className={classes.bold}>{`${chart.key}. ${chart.title}`}</div>
                ))}
              </TabPanel>
            </>
          )}
        </Block>
      </Modal>
    </>
  );
});
