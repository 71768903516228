import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      gap: 24,
      minHeight: '100%',
      padding: '36px 48px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      color: theme.palette.gloomyBlue.main,
      font: {
        size: 32,
        weight: 700,
      },
      lineHeight: '40px',
    },
    count: {
      marginRight: 20,
      display: 'flex',
      alignItems: 'center',
      color: '#909EBB',
      lineHeight: '20px',
    },
    courses: {
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      padding: '32px 40px',
      backgroundColor: '#FFFFFF',
      borderRadius: 16,
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      padding: '14px 24px',
      border: '1px solid #99C6EB',
      cursor: 'text',
      backgroundColor: '#F5FBFF',
      borderRadius: 8,
      '& > svg': {
        color: '#99C6EB',
      },
      '&:focus-within': {
        borderColor: '#73AAFF',
        '& > svg': {
          color: '#73AAFF',
        },
      },
    },
    searchInput: {
      flexGrow: 1,
      backgroundColor: 'transparent',
      lineHeight: '20px',
      outline: '2px solid transparent',
      outlineOffset: 0,
    },
    coursesList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      rowGap: 24,
      columnGap: 32,
    },
    notFound: {
      textAlign: 'center',
      gridColumn: 'span 2',
    },
    course: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      padding: 32,
      border: '1px solid #E4E7F2',
      borderRadius: 16,
      cursor: 'pointer',
      wordBreak: 'break-word',
      '&:hover': {
        backgroundColor: '#F5FBFF',
        borderColor: '#99C6EB',
      },
    },
    courseTitle: {
      maxWidth: '44vw',
      color: '#1E253B',
      font: {
        size: 20,
        family: theme.fontFamily.monserrat,
        weight: 600,
      },
      lineHeight: '24px',
    },
    courseInfo: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '8px 16px',
      rowGap: 8,
      columnGap: 16,
      color: '#909EBB',
      border: '1px solid #E4E7F2',
      borderRadius: 8,
      fontSize: 14,
      lineHeight: '20px',
    },
    courseLabel: {
      fontWeight: 500,
    },
    status_info: {
      color: '#73AAFF',
    },
    status_warning: {
      color: '#F7B055',
    },
    status_success: {
      color: '#15B012',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
    },
    closeIcon: {
      cursor: 'pointer',
      marginLeft: 'auto',
      color: theme.palette.secondaryText.main,
      width: 20,
      height: 20,

      '&:hover': {
        color: theme.palette.gloomyBlue.main,
      },
    },
    modalTitle: {
      color: '#3B4168',
      font: {
        size: 24,
        weight: 600,
      },
      lineHeight: '28px',
    },
    '@media (max-width: 1320px)': {
      courseTitle: {
        maxWidth: 'initial',
      },
    },
    '@media (max-width: 1280px)': {
      courses: {
        rowGap: 24,
      },
    },
    '@media (max-width: 1024px)': {
      root: {
        padding: '40px 28px',
      },
      coursesList: {
        gridTemplateColumns: '1fr',
      },
    },
    '@media (max-width: 768px)': {
      header: {
        flexDirection: 'column',
        rowGap: 8,
      },
      courses: {
        padding: 24,
        rowGap: 32,
      },
      coursesList: {
        rowGap: 16,
      },
      course: {
        padding: 24,
      },
    },
    '@media (max-width: 640px)': {
      root: {
        padding: 0,
        paddingTop: 34,
      },
    },
  }),
  { name: 'CoursesPage' },
);
