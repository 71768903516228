import { IGrade } from '@unione-pro/unione.assmnt.sdk.webapp';
import { ICompetencyLevelResult, LevelKeys } from '../models/stores/testing.store';

export const LEVEL_RESULT_INFO: Record<LevelKeys, ICompetencyLevelResult> = {
  expert: { levelLabel: 'Экспертный', competencyLevel: 'expert_level' },
  advanced: { levelLabel: 'Продвинутый', competencyLevel: 'advanced_level' },
  normal: { levelLabel: 'Базовый', competencyLevel: 'normal_level' },
  min: { levelLabel: 'Минимальный исходный', competencyLevel: 'basic_level' },
};

export const LEVEL_RESULT_INFO_BY_GRADE: Record<IGrade, ICompetencyLevelResult> = {
  expert_level: LEVEL_RESULT_INFO.expert,
  advanced_level: LEVEL_RESULT_INFO.advanced,
  normal_level: LEVEL_RESULT_INFO.normal,
  basic_level: LEVEL_RESULT_INFO.min,
};

export const MIN_SCORE_LEVEL: Record<LevelKeys, number> = {
  expert: 0.8,
  advanced: 0.44,
  normal: 0.18,
  min: 0,
};

export const GRID_COMPETENCY_HEADER_LABELS = ['Компетенция', 'Оценка', 'Уровень', 'Описание'];
export const GRID_SPHERE_HEADER_LABELS = ['Сфера', 'Оценка', 'Уровень'];
