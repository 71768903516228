import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '20px 40px',
      border: {
        radius: 12,
        width: 1,
        style: 'solid',
        color: theme.palette.lines.main,
      },
      backgroundColor: theme.background.paper,
      columnGap: 16,
      rowGap: 16,
    },
    icon: {
      width: 32,
      height: 32,
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
      borderRadius: '100%',
      padding: 6,
    },
    text: {
      width: '100%',
      cursor: 'pointer',
    },
    button: {
      height: 32,
      width: '100%',
      maxWidth: 'fit-content',
      padding: '8px 16px !important',
      fontSize: 12,
      lineHeight: '16px',
    },
    modal: {
      padding: '32px !important',
      maxWidth: '480px !important',
    },
    closeIcon: {
      cursor: 'pointer',
      marginLeft: 'auto',
      color: theme.palette.secondaryText.main,
      width: 20,
      height: 20,

      '&:hover': {
        color: theme.palette.gloomyBlue.main,
      },
    },
    header: {
      display: 'flex',
      flexFlow: 'column',
      rowGap: 12,
    },
    title: {
      color: theme.palette.gloomyBlue.main,
      font: {
        family: 'Montserrat',
        style: 'normal',
        size: 24,
        weight: 600,
      },
      lineHeight: '28px',
    },
    subTitle: {
      color: theme.palette.secondaryText.main,
      font: {
        style: 'normal',
        size: 16,
        weight: 400,
      },
      lineHeight: '20px',
    },
    causes: {
      display: 'flex',
      flexFlow: 'column',
      rowGap: 12,
      marginBottom: 8,
    },
    checkbox: {
      width: 'fit-content',
      display: 'flex',
      columnGap: 12,
      color: theme.palette.gloomyBlue.main,
      font: {
        style: 'normal',
        size: 16,
        weight: 400,
      },
      lineHeight: '24px',
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    textareaContainer: {
      position: 'relative',
    },
    textarea: {
      height: '100%',
      minHeight: 100,
      resize: 'none',
      color: theme.palette.secondaryText.main,
      padding: '14px 20px',
      font: {
        style: 'normal',
        size: 16,
        weight: 400,
      },
      lineHeight: '24px',
      border: {
        radius: 12,
        width: 1,
        style: 'solid',
        color: theme.palette.lines.main,
      },
      width: '100%',
    },
    charCounter: {
      position: 'absolute',
      right: 0,
      bottom: '-12px',
      fontSize: 12,
      color: '#999999',
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '12px !important',
    },
    actionButton: {
      width: '100%',
      maxWidth: 'fit-content',
      padding: '12px 24px !important',
      font: {
        style: 'normal',
        size: 14,
        weight: 500,
      },
      lineHeight: '20px',
    },
    cancel: {
      backgroundColor: `${theme.palette.lightBlue.main} !important`,

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: 'white !important',
      },
    },
    '@media (max-width: 710px)': {
      root: {
        flexFlow: 'column',
        justifyContent: 'center',
        padding: '14px 16px',
      },
      text: {
        textAlign: 'center',
      },
    },
  }),
  { name: 'QuestionFeedback' },
);
