import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      color: theme.palette.gloomyBlue.main,
      fontSize: 16,
      lineHeight: '20px',
    },
    title: {
      font: {
        family: theme.fontFamily.monserrat,
        weight: 500,
        size: 18,
      },
      lineHeight: '24px',
    },
    questions: {
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
    },
    question: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },
    questionItem: {
      cursor: 'pointer',
      display: 'grid',
      gridTemplateColumns: '24px 1fr',
      columnGap: 16,
    },
    answer: {
      width: 'fit-content',
      backgroundColor: 'rgba(0, 113, 206, 0.07)',
      border: {
        width: 1,
        style: 'solid',
        color: '#99C6EB',
        radius: 8,
      },
      padding: 16,
    },
    '@media (max-width: 768px)': {
      root: {
        rowGap: 32,
      },
    },
  }),
  { name: 'question-case-tree' },
);
