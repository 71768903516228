import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: '100%',
      padding: '36px 48px 0',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    certificates: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    certificatesInfo: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
    },
    title: {
      color: '#3B4168',
      margin: 0,
      backgroundColor: 'transparent',
      font: {
        size: 32,
        weight: 700,
      },
      lineHeight: '40px',
    },
    count: {
      color: '#909EBB',
    },
    table: {
      backgroundColor: theme.background.paper,
      borderRadius: 8,
      padding: ['20px', '40px'],
      maxHeight: 'calc(100vh - 180px)',
      overflow: 'auto',
    },
    tableHeaderCols: {
      minWidth: 124,
      padding: ['16px', '11px'],
      borderBottom: '1px solid #E4E7F2',
    },
    tableTitleSize: {
      width: '50%',
    },
    tableDateSize: {
      width: '15%',
    },
    tableStatusSize: {
      flexGrow: 1,
    },
    tableBodyCols: {
      padding: ['16px', '12px'],
      minWidth: 124,
    },
    download: {
      padding: {
        top: 16,
        bottom: 16,
        left: 12,
        right: 50,
      },
      display: 'flex',
      justifyContent: 'center',
      color: '#909EBB',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    disabled: { pointerEvents: 'none' },
    desktop: {},
    secondaryText: {
      color: theme.palette.secondaryText.main,
    },
    mobile: {
      display: 'none',
    },
    '@media (max-width: 1320px)': {
      table: {
        maxHeight: 'calc(100vh - 233px)',
      },
    },
    '@media (max-width: 1024px)': {
      desktop: {
        display: 'none',
      },
      download: {
        padding: 0,
      },
      mobile: {
        display: 'block',
      },
      tableBodyCols: {
        gap: 8,
      },
      tableTitleSize: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: 0,
        font: {
          size: 18,
          weight: 500,
          family: theme.fontFamily.monserrat,
        },
        lineHeight: '24px',
      },
      tableDateSize: {
        display: 'flex',
        width: '100%',
        padding: 0,
      },
    },
    '@media (max-width: 768px)': {
      table: {
        padding: 24,
        maxHeight: 'initial',
      },
    },
    '@media (max-width: 640px)': {
      root: {
        padding: {
          top: 34,
          bottom: 0,
          right: 0,
          left: 0,
        },
      },
      certificates: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
      },
    },
  }),
  { name: 'Certificates' },
);
