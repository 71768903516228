import {
  ProctoTypes,
  sdkCheckFace,
  sdkCheckFaceV2,
  sdkGetProctoringToken,
  sdkPostSnapshotsV2,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import { LOGGER_ACTION } from '../constants/actions';
import { ACTIVE_COURSES } from '../constants/builder-course.constants';
import { ERRORS } from '../constants/proctoring.constants';
import { engineErrorMessages } from '../models/entities/testing.models';
import { IProctoringStore, IProctoActiveCourse } from '../models/stores/proctoring.store';
import { IRootStore } from '../models/stores/root.store';
import { IStartProctoSession } from '../models/stores/testing.store';
import { converterDataURItoBlob } from '../shared/data-uri-to-blob';
import { getErrorMessage } from '../shared/error-message';
import { IUserMedia } from '../shared/use-get-user-media';
import { IUseScriptOpts } from '../shared/use-script';

export class ProctoringStore implements IProctoringStore {

  public readonly rootStore: IRootStore;

  public error: string | undefined = undefined;
  public loading: boolean = false;
  public supervisor: Supervisor = undefined;
  public userMedia: IUserMedia = undefined;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  public setUserMedia = (userMedia: IUserMedia): void => {
    this.userMedia = userMedia;
  };

  public getProctoToken = async(): Promise<string | undefined> => {
    const currentCourse = this.rootStore.builderCourse.data;
    const activeStage = currentCourse?.stages?.find((stage) => stage.isActive);

    if (!currentCourse?._id || !activeStage?._id) {
      return undefined;
    }

    const courses: IProctoActiveCourse[] = JSON.parse(localStorage.getItem(ACTIVE_COURSES) || '[]');

    const activeCourses = courses.filter((course) => {
      const diff = dayjs(course.expirationDate).diff();
      return diff > 0;
    });

    const requestArgs = {
      course_id: currentCourse._id,
      stage_id: activeStage._id,
      template: 'default',
    };

    try {
      const activeCourse = activeCourses.find((course) => course.id === currentCourse._id);

      if (activeCourse?.token) {
        return activeCourse.token;
      }

      const response = await sdkGetProctoringToken({
        baseURL: this.rootStore.config.externalProctoAPI,
        token: this.rootStore.users.auth.data?.token,
        data: requestArgs,
      });

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.GET_PROCTO_TOKEN,
        request: requestArgs,
      });

      activeCourses.push({
        id: currentCourse._id,
        token: response.token,
        expirationDate: dayjs().add(1, 'h').format('MM DD YYYY HH:mm:ss'),
      });

      return response.token;
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
      });

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.GET_PROCTO_TOKEN,
        request: requestArgs,
        error,
      });

      return undefined;
    }
    finally {
      if (activeCourses.length) {
        localStorage.setItem(ACTIVE_COURSES, JSON.stringify(activeCourses));
      }
      else {
        localStorage.removeItem(ACTIVE_COURSES);
      }
    }
  };

  public postSnapshots = async(stageId: string): Promise<boolean> => {
    if (!this.isInternal) {
      return false;
    }

    try {
      runInAction(() => {
        this.loading = true;
        this.error = undefined;
      });

      const snapshot = this.userMedia.takeSnapshot();
      const formData = new FormData();

      formData.append('picture', converterDataURItoBlob(snapshot));
      formData.append('course_id', this.rootStore.builderCourse.data._id);
      formData.append('user_id', this.rootStore.users.builder.data.id);
      formData.append('stage_id', stageId);

      const response = await sdkPostSnapshotsV2({
        baseURL: this.rootStore.config.proctoAPIV2,
        token: this.rootStore.users.auth.data?.token,
        data: formData,
      });

      if (response?.errors?.length > 0) {
        toast({
          type: 'error',
          text: engineErrorMessages[response.errors[0].key] ?? 'Ошибка сервера',
        });
      }

      runInAction(() => {
        this.loading = false;
      });

      return response.status;
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.loading = false;
        this.error = getErrorMessage(error);
      });

      return false;
    }
  };

  public checkFace = async(stageId: string): Promise<boolean> => {
    const snapshot = this.userMedia.takeSnapshot();

    const { baseURL, request, snapshotKey } = this.generateCheckFaceRequestConfig();

    const formData = new FormData();

    formData.append(snapshotKey, converterDataURItoBlob(snapshot));
    formData.append('course_id', this.rootStore.builderCourse.data._id);
    formData.append('user_id', this.rootStore.users.builder.data.id);
    formData.append('stage_id', stageId);

    const response = await request({
      baseURL,
      token: this.rootStore.users.auth.data?.token,
      data: formData,
    });

    return response.status;
  };

  public generateCheckFaceRequestConfig = (): {
    request: typeof sdkCheckFace;
    baseURL: string;
    snapshotKey: string;
  } => {
    const { proctoAPI, proctoAPIV2 } = this.rootStore.config;

    if (proctoAPIV2) {
      return {
        baseURL: proctoAPIV2,
        request: sdkCheckFaceV2,
        snapshotKey: 'picture',
      };
    }

    return {
      baseURL: proctoAPI,
      request: sdkCheckFace,
      snapshotKey: 'file',
    };
  };

  public removeFromLocalStorage = (): void => {
    const courses: IProctoActiveCourse[] = JSON.parse(localStorage.getItem(ACTIVE_COURSES) || '[]');
    const courseId = this.rootStore.builderCourse.data._id;
    const activeCourses = courses.filter((course) => course.id !== courseId);

    if (activeCourses.length) {
      localStorage.setItem(ACTIVE_COURSES, JSON.stringify(activeCourses));
    }
    else {
      localStorage.removeItem(ACTIVE_COURSES);
    }
  };

  public startInternalProctoSession = (stageId: string): Promise<void> => new Promise((resolve, reject) => {
    const hasProctoImage = this.rootStore.users.builder.data.proctoring_image;

    if (!hasProctoImage) {
      reject(new Error(ERRORS.notFoundProctoImages));
      return;
    }

    this.userMedia.handleEnableCamera().then((enabled) => {
      if (!enabled) {
        reject(new Error(ERRORS.disabledCamera));
        return;
      }

      setTimeout(async() => {
        const canStart = await this.checkFace(stageId);

        if (!canStart) {
          reject(new Error(ERRORS.notFoundFace));
          return;
        }

        await this.postSnapshots(stageId);

        resolve();
      }, 1000);
    });
  });

  public startExternalProctoSession = async(): Promise<void> => {
    const token = await this.getProctoToken();

    if (!token) {
      throw new Error(ERRORS.notStart);
    }

    const res = await this.supervisor?.init({ provider: 'jwt', token });

    if (!Boolean(res)) {
      throw new Error(ERRORS.notStart);
    }

    await this.supervisor.start();
  };

  public startProctoSession = async(opts: IStartProctoSession): Promise<void> => {
    if (!opts.isProcto) {
      return;
    }

    if (this.isInternal) {
      await this.startInternalProctoSession(opts.stageId);
    }
    else if (this.isExternal) {
      try {
        await this.startExternalProctoSession();
        this.rootStore.logger.sendLog({
          action: LOGGER_ACTION.EXTERNAL_PROCTO_INIT,
          request: {},
        });
      }
      catch (error) {
        this.rootStore.logger.sendLog({
          action: LOGGER_ACTION.EXTERNAL_PROCTO_INIT,
          request: {},
          error,
        });

        // TODO нужно чтобы выкинуло из курса, в случае если пользователь не прошел проверку прокторинга
        throw Error(error);
      }
    }
  };

  public finishProctoSession = async(stageId: string): Promise<void> => {
    if (this.isInternal) {
      await this.postSnapshots(stageId);
      this.userMedia.handleDisableCamera();
    }
    else if (this.isExternal) {
      await this.supervisor.stop();
      await this.supervisor.logout();
      this.removeFromLocalStorage();
    }
  };

  public closeProctoSession = async(): Promise<void> => {
    if (this.isInternal) {
      this.userMedia.handleDisableCamera();
    }
    else if (this.isExternal) {
      await this.supervisor.close();
    }
  };

  public onload = (): void => {
    const url = this.rootStore.config.proctorEduAPI;
    this.supervisor = new Supervisor({ url });
  };

  get isInternal(): boolean {
    const isProctoCourse = this.rootStore.builderCourse.isProcto;
    const proctoType = this.rootStore.users.builder.data.is_procto;

    return isProctoCourse && proctoType === ProctoTypes.internal;
  }

  get isExternal(): boolean {
    const isProctoCourse = this.rootStore.builderCourse.isProcto;
    const proctoType = this.rootStore.users.builder.data.is_procto;

    return isProctoCourse && proctoType === ProctoTypes.external;
  }

  get scriptOpts(): IUseScriptOpts {
    if (!this.isExternal) {
      return undefined;
    }

    const src = this.rootStore.config.supervisorSDK;

    return { src, onload: this.onload };
  }

}
