import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  () => ({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: '24px 48px',
    },
    answersList: {
      width: '100%',
      height: 'calc(100vh - 235px)',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    borderBlue: {
      borderColor: 'rgb(59 130 246)',
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loading: {
      width: '100%',
      height: '100%',
      flexGrow: 1,
    },
    resultNotFound: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      flexGrow: 1,
      padding: '24px 48px',
    },
    notFoundTitle: {
      width: '100%',
      flexGrow: 1,
      fontSize: 24,
      lineHeight: '32px',
    },
    '@media (max-width: 1320px)': {
      answersList: {
        height: 'calc(100vh - 290px)',
      },
    },
    '@media (max-width: 640px)': {
      root: {
        padding: 0,
      },
      answersList: {
        height: 'calc(100vh - 235px)',
      },
      resultNotFound: {
        padding: 0,
      },
    },
  }),
  { name: 'CourseStageAnswers' },
);
