import { createUseStyles } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/theme/createUseStyles';

export const useAttentionModalStyles = createUseStyles(
  (theme) => ({
    modal: {},
    flex: {},
    title: {
      color: theme.palette.gloomyBlue.main,
      font: {
        size: 24,
        weight: 700,
      },
    },
    description: {
      color: theme.palette.secondaryText.main,
    },
    icon: {
      background: theme.palette.error.main,
      color: theme.background.paper,
      borderRadius: 12,
      width: 'fit-content',
      padding: 12,
      margin: '0 auto',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      gap: 8,
      marginBottom: 10,
    },
    '@media (max-width: 540px)': {
      modal: {
        height: '100%',
        maxWidth: 'none',
      },
      flex: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      },
    },
  }),
  { name: 'AttentionModal' },
);
