import { useCallback, useEffect, useRef, useState } from 'react';
import { MINUTE_MS } from '../constants/timer.constants';

/**
 * Хук, который отслеживает изменение видимости вкладки и вызывает функцию обратного вызова по истечении заданного времени ожидания.
 * @param callbackFn - Функция, вызываемая после таймаута.
 * @param timeout - Таймаут в миллисекундах. По умолчанию — 60000 (1 минута).
 */
export const useTabVisibility = (callbackFn: () => void, timeout = MINUTE_MS): void => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [isNeedToCall, setIsNeedToCall] = useState<boolean>(false);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      timeoutRef.current = setTimeout(() => setIsNeedToCall(true), timeout);
      return;
    }

    if (isNeedToCall) {
      callbackFn();
      setIsNeedToCall(false);
    }

    clearTimeout(timeoutRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToCall]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearTimeout(timeoutRef.current);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);
};
