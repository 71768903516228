import { FC, PropsWithChildren } from 'react';
import { Toaster } from 'react-hot-toast';

export const Layout: FC<PropsWithChildren> = (props) => (
  <div className="min-h-[calc(100vh-64px)] flex flex-col">
    {props.children}
    <Toaster
      position="top-right"
      reverseOrder={false}
    />
  </div>
);
