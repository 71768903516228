import { FC, HTMLAttributes } from 'react';
import { Link } from 'react-scroll';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { useStyles } from './use-styles';

export interface IPaginationScrollItem {
  label: string;
  href: string;
  answered?: boolean;
}

type PaginationScrollProps<T> = JSX.IntrinsicElements['div'] & {
  options: T[];
  getOptionProps?: (el: T) => HTMLAttributes<HTMLDivElement>;
  containerId?: string;
  onClickItem?: (el: T) => void;
};

export const PaginationScroll: FC<PaginationScrollProps<IPaginationScrollItem>> = (props) => {
  const { options, className, getOptionProps, containerId, onClickItem = (): void => {}, ...other } = props;
  const classes = useStyles();

  return (
    <Paper {...other} className={cn(classes.root, className)} data-tour="test-step-3">
      <div className={cn(classes.linkWrapper, 'scrollbar')}>
        {options.map((el) => {
          const { className: elClassName = undefined, ...elProps } = getOptionProps ? getOptionProps(el) : {};

          return (
            <Link
              containerId={containerId}
              key={el.label}
              to={el.href}
              smooth={true}
              offset={0}
              duration={500}
              onClick={(): void => onClickItem(el)}
            >
              <div data-link={el.href} {...elProps} className={cn(classes.label, elClassName)}>
                {el.label}
              </div>
            </Link>
          );
        })}
      </div>
    </Paper>
  );
};
