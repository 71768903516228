import { ITesterCertificate, sdkGetCertificates } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx';
import { ICertificatesStore } from '../models/stores/certificates.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class CertificatesStore implements ICertificatesStore {

  public readonly rootStore: IRootStore;

  public data: ITesterCertificate[] = [];
  public error: undefined | string;
  public loading: boolean = true;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
    onBecomeObserved(this, 'data', this.fetchCertificates);
  }

  public fetchCertificates = async(): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true;
        this.error = undefined;
      });

      const { certificates } = await sdkGetCertificates({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.users.auth.data.token,
        data: undefined,
      });

      runInAction(() => {
        this.data = certificates;
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  };

}
