import { AxiosResponseHeaders } from 'axios';
import { ICertificatesStore } from '../models/stores/certificates.store';
import { ILoggerStore } from '../models/stores/logger.store';
import { IRootStore } from '../models/stores/root.store';
import { BuilderCourseStore } from './builder.course.store';
import { CertificatesStore } from './certificates.store';
import { ConfigStore } from './config.store';
import { LoggerStore } from './logger.store';
import { ProctoringListStore } from './proctoring-list.store';
import { ProctoringStore } from './proctoring.store';
import { QuestionFeedbackStore } from './questions-feedback.store';
import { TestingStore } from './testing/testing.store';
import { UserStore } from './user.store';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly builderCourse: BuilderCourseStore;
  public readonly users: UserStore;
  public readonly testing: TestingStore;
  public readonly proctoring: ProctoringStore;
  public readonly proctoringList: ProctoringListStore;
  public readonly logger: ILoggerStore;
  public readonly certificates: ICertificatesStore;
  public readonly questionsFeedback: QuestionFeedbackStore;

  public serverTime?: number = undefined;

  constructor() {
    this.config = new ConfigStore();
    this.users = new UserStore(this);
    this.testing = new TestingStore(this);
    this.builderCourse = new BuilderCourseStore(this);
    this.proctoring = new ProctoringStore(this);
    this.proctoringList = new ProctoringListStore(this);
    this.logger = new LoggerStore(this);
    this.certificates = new CertificatesStore(this);
    this.questionsFeedback = new QuestionFeedbackStore(this);
  }

  public setServerTime = (headers: AxiosResponseHeaders): void => {
    const serverTime = headers['x-server-time'];

    if (serverTime) {
      this.serverTime = Date.parse(serverTime);
    }
  };

}
