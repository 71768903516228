import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cn from 'classnames';
import { ArrowLeftLongIcon } from '../icons/arrow-left-long';

interface IBackLinkProps extends LinkProps {
  label: string;
}

export const BackLink: FC<IBackLinkProps> = ({ label, className, ...props }) => (
  <Link
    className={cn('flex items-center gap-2 text-blue-700 font-medium', className)}
    {...props}
  >
    <ArrowLeftLongIcon /> {label}
  </Link>
);
