import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    stage: {
      display: 'flex',
      gap: 20,
      flexDirection: 'column',
      padding: '20px 40px',
      flexBasis: '100%',
      minWidth: 265,
    },
    stageHeader: {
      display: 'flex',
      gap: 8,
      justifyContent: 'space-between',
      marginBottom: 8,
    },
    procto: {
      display: 'flex',
      width: 24,
      alignItems: 'center',
      color: '#909EBB',
      '& > svg': {
        width: 24,
        height: 24,
      },
    },
    stageBody: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    secondaryText: {
      color: theme.palette.secondaryText.main,
    },
    btn: {
      padding: '12px 24px',
      textAlign: 'left',
      width: 'fit-content',
      '&.Button-variantStandard': {
        padding: [2, 0],
      },
    },
    '@media (max-width: 1320px)': {
      stage: {
        padding: '20px 32px',
      },
    },
    '@media (max-width: 768px)': {
      stage: {
        padding: 24,
      },
    },
  }),
  { name: 'TestingCard' },
);
