import { FC } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { CertificateIcon } from '../icons/certificate';
import { CoursesIcon } from '../icons/courses';
import { LinkIcon } from '../icons/link';
import { ProctoringIcon } from '../icons/proctoring';
import { ResumeModal } from '../resume-modal/resume-modal';
import { useStyles } from './use-styles';

export const Sidebar: FC = observer(() => {
  const classes = useStyles();
  const { stage } = useParams();
  const navigation = [
    { label: 'Оценочные сессии', path: BrowserRoute.courses, icon: <CoursesIcon className={classes.purple} /> },
    { label: 'Прокторинг', path: BrowserRoute.proctoring, icon: <ProctoringIcon className={classes.green} /> },
    { label: 'Сертификаты', path: BrowserRoute.certificates, icon: <CertificateIcon className={classes.orange} /> },
  ];

  return (
    <div className={cn(classes.root, { [classes.mobileHidden]: !!stage })}>
      {navigation.map((link) => (
        <NavLink
          key={link.label}
          to={link.path}
          className={({ isActive }): string => cn(classes.link, { [classes.active]: isActive })}
        >
          <div className={classes.icon}>{link.icon}</div>
          <span className={classes.label}>{link.label}</span>
        </NavLink>
      ))}
      <ResumeModal />
      <a
        href="https://innopolis.university/filespublic/Instrukciya_dlya_obuchayushchihsya.pdf"
        target="_blank"
        referrerPolicy="no-referrer"
        className={classes.link}
        rel="noreferrer"
      >
        <LinkIcon className={classes.icon} />
        <span className={classes.label}>Руководство&nbsp;пользователя</span>
      </a>
    </div>
  );
});
