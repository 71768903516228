import { FC, HTMLAttributes, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UpdateMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Button, Modal } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Confirm } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/confirm';
import { CloseIcon } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/icons/close';
import { ImageModal } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/image-modal';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { LOGGER_ACTION } from '../../../constants/actions';
import { MAX_PROGRESS, TWENTY_SECONDS_IN_MS } from '../../../constants/course-stage.constants';
import { DECLENSION_OPTIONS_MIN, DECLENSION_OPTIONS_SEC, MODAL_OPTIONS, secondsInMinute } from '../../../constants/timer.constants';
import { CourseStageQuery } from '../../../models/entities/builder-course.models';
import { IWarningModal } from '../../../models/entities/stages.models';
import { BrowserRoute } from '../../../routes/browser.routes';
import { declensionByNumber } from '../../../shared/declensionByNumber';
import { getValuePercent } from '../../../shared/get-value-percent';
import { useTabVisibility } from '../../../shared/use-tab-visibility';
import { useTimer } from '../../../shared/use-timer';
import { useAppStore } from '../../../stores/context.store';
import { getQuestionAnswer } from '../../components/answer-preview/utils';
import { Block } from '../../components/block';
import { AlarmIcon } from '../../components/icons/alarm';
import { DoneIcon } from '../../components/icons/done';
import { Timer } from '../../components/icons/timer';
import { WarningV2Icon } from '../../components/icons/warning-v2';
import { IPaginationScrollItem, PaginationScroll } from '../../components/pagination-scroll';
import { QuestionPreview } from '../../components/question-preview';
import { CircularProgress } from '../../lab/circular_progress';
import { IUpdateAnswerOpts } from './course-stage.models';
import { useStyles } from './use-styles';
import { confirmMessages, getAnswersCount } from './utils';

const getRemainingTime = (seconds: number): string => {
  if (seconds > secondsInMinute) {
    const remainingTime = declensionByNumber(Math.round(seconds / secondsInMinute), DECLENSION_OPTIONS_MIN);
    return `У вас осталось ${remainingTime} для завершения оценки!`;
  }
  return `Ваше время закончится через ${declensionByNumber(seconds, DECLENSION_OPTIONS_SEC)}.`;
};

export const CourseStagePage: FC = observer(() => {
  const [search, setSearchParams] = useSearchParams();
  const { id, stage: stageOrder } = useParams();
  const appStore = useAppStore();
  const classes = useStyles();
  const confirm = useModalState();
  const imagePreview = useModalState<string | undefined>();
  const attemptsModal = useModalState();
  const timerModal = useModalState();
  const testingTimer = useRef(null);
  const navigate = useNavigate();
  const [confirmMessage, setConfirmMessage] = useState<string>(confirmMessages.default);
  const [modalOptions, setModalOptions] = useState<IWarningModal>(null);

  const questionNumber = Number(search.get(CourseStageQuery.question) ?? 1);

  const { isOneByOneMode, model, data: courseData, isHideAnswers } = appStore.builderCourse;
  const { updateQuestion, userTest, testingSession } = appStore.testing;
  const { closeProctoSession } = appStore.proctoring;
  const { data, error, loading, closeTest, finishTest, reset } = userTest;
  const { destroySession } = testingSession;

  // TODO необходимо для того, чтобы в локальной разработке все работало так же, будто бы стрикт мод отключен
  const [isMounted, setIsMounted] = useState(false);

  const [showModalTimer, setShowModalTimer] = useState(false);
  const [isTimerWarning, setIsTimerWarning] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    const activeStage = courseData?.stages?.find((stage) => stage.order === Number(stageOrder));

    return () => {
      const onUnmount = (): void => {
        if (activeStage?.is_procto) {
          closeProctoSession();
        }
        reset();
      };
      if (isMounted) {
        destroySession();
        onUnmount();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useTabVisibility(() => {
    navigate(BrowserRoute.course(id));
  });

  useEffect(() => {
    if (!data || !courseData) {
      navigate(BrowserRoute.course(id));
    }
  }, [data, id, navigate, courseData]);

  const stageEndDate = useMemo((): number => {
    const activeStage = courseData?.stages?.find((stage) => stage.order === Number(stageOrder));
    const currentDate = dayjs();
    const targetDate = dayjs(activeStage?.end_date);
    const differenceInMilliseconds = targetDate.diff(currentDate);
    const differenceInSeconds = dayjs.duration(differenceInMilliseconds).asSeconds();

    if (data?.timeLeft > differenceInSeconds) {
      setShowModalTimer(true);
      setIsTimerWarning(true);
      return differenceInSeconds - 60;
    }

    return data?.timeLeft || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseData]);

  const { timer, timeLeft, hour, minute } = useTimer({
    timeLeft: parseInt(stageEndDate.toFixed()),
    finish: () => {
      handleCloseTimerModal();
      navigate(BrowserRoute.course(id));
    },
  });

  useEffect(() => {
    const options = MODAL_OPTIONS[timeLeft];

    if (options) {
      setModalOptions(options);
    }

    if (timeLeft === 0 && isTimerWarning) {
      const timeLeftFinishSession = async(): Promise<void> => {
        await finishTest(stageOrder);
        const navigatePath = isHideAnswers ? BrowserRoute.course(id) : BrowserRoute.courseStageAnswers(id, stageOrder);
        navigate(navigatePath);
      };
      timeLeftFinishSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const pagination = useMemo(
    () => data?.questions.map((el, i) => ({
      label: `${i + 1}`,
      href: el['question-id'],
      answered: Boolean(getQuestionAnswer(el).length),
    })),
    [data],
  );

  const progress = useMemo(() => {
    const questions = data?.questions || [];
    const questionsCount = questions.length;
    const answersCount = getAnswersCount(questions);

    return getValuePercent(answersCount, questionsCount);
  }, [data]);

  const handleSetCurrentQuestion = (value: string): void => {
    if (isOneByOneMode) {
      search.set(CourseStageQuery.question, value ?? '');
      setSearchParams(search.toString());
    }
  };

  const handleSetPrevQuestion = (): void => {
    if (questionNumber > 1) {
      appStore.logger.sendLog({
        action: LOGGER_ACTION.CLICK_PREV_ANSWER,
        request: { questionNumber: questionNumber - 1 },
      });
      handleSetCurrentQuestion(String(questionNumber - 1));
    }
  };

  const handleSetNextQuestion = (): void => {
    if (questionNumber < data.questions.length) {
      appStore.logger.sendLog({
        action: LOGGER_ACTION.CLICK_NEXT_ANSWER,
        request: { questionNumber: questionNumber + 1 },
      });
      handleSetCurrentQuestion(String(questionNumber + 1));
    }
  };

  const handleClickPaginationItem = ({ label }): void => {
    handleSetCurrentQuestion(label);
    appStore.logger.sendLog({
      action: LOGGER_ACTION.CLICK_NUMBER_TASK,
      request: { questionNumber: label },
    });
  };

  const getOptionProps = useCallback(
    (el: IPaginationScrollItem): HTMLAttributes<HTMLDivElement> => {
      const className = cn({
        [classes.answered]: el.answered,
        [classes.active]: isOneByOneMode && el.label === String(questionNumber),
      });

      return { className };
    },
    [classes, questionNumber, isOneByOneMode],
  );

  const handleUpdateAnswer = (opts: IUpdateAnswerOpts): void => {
    updateQuestion.updateQuestionAnswer({ ...opts, stageOrder });
  };

  const handleFinishTest = async(): Promise<void> => {
    await finishTest(stageOrder);
    appStore.logger.sendLog({
      action: LOGGER_ACTION.CLICK_BUTTON_FINISH_MODAL,
      request: {},
    });

    const navigatePath = isHideAnswers ? BrowserRoute.course(id) : BrowserRoute.courseStageAnswers(id, stageOrder);
    navigate(navigatePath);
  };

  const handleClose = async(): Promise<void> => {
    await closeTest(stageOrder);
    navigate(BrowserRoute.course(id));
  };

  // confirm events
  const mouseMoveHandler = (): void => {
    clearTimeout(testingTimer.current);
    if (progress === MAX_PROGRESS) {
      testingTimer.current = setTimeout(() => {
        openCompleteConfirm();
      }, TWENTY_SECONDS_IN_MS);
    }
  };

  const openCompleteConfirm = (): void => {
    if (progress === MAX_PROGRESS) {
      setConfirmMessage(confirmMessages.custom);
      confirm.open();
    }
  };

  const onClickFinish = (): void => {
    setConfirmMessage(confirmMessages.default);
    confirm.open();
  };

  useEffect(() => {
    mouseMoveHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    if (modalOptions) {
      timerModal.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOptions]);

  useEffect(() => {
    if (data?.attempt === model?.common_params.count_of_attempts) {
      attemptsModal.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.attempt]);

  useEffect(() => {
    if (isOneByOneMode && data?.questions && courseData) {
      const question = data.questions[questionNumber - 1];

      updateQuestion.updateQuestionAnswer({
        questionId: question['question-id'],
        type: question.type,
        mode: UpdateMode.open,
        stageOrder,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionNumber]);

  const handleCloseTimerModal = (): void => {
    setModalOptions(null);
    timerModal.close();
    appStore.logger.sendLog({
      action: LOGGER_ACTION.CLICK_BUTTON_CLOSE_MODAL,
      request: {},
    });
  };

  const handleCloseConfirm = (): void => {
    appStore.logger.sendLog({
      action: LOGGER_ACTION.CLICK_BUTTON_CLOSE_MODAL,
      request: {},
    });
    confirm.close();
  };

  const handleCloseTimeLeftModal = (): void => {
    setShowModalTimer(false);
  };

  return (
    <Block data={data} error={error} loading={loading}>
      {(test): ReactElement => (
        <div
          className={classes.root}
          onWheel={mouseMoveHandler}
          onMouseMove={mouseMoveHandler}
          onMouseLeave={openCompleteConfirm}
        >
          <h1 className={classes.title}>Оценка #1</h1>

          <PaginationScroll
            containerId="questions-scroll"
            options={pagination}
            getOptionProps={getOptionProps}
            onClickItem={handleClickPaginationItem}
            className={classes.pagination}
          />

          <div className={classes.content}>
            <div className={cn(classes.questions, 'scrollbar')} id="questions-scroll">
              {updateQuestion.loading && (
                <div className={classes.loader}><CircularProgress /></div>
              )}
              {isOneByOneMode ? (
                <QuestionPreview
                  key={questionNumber - 1}
                  question={test.questions[questionNumber - 1]}
                  index={questionNumber - 1}
                  onUpdate={handleUpdateAnswer}
                  onImagePreview={(src): void => imagePreview.open(src)}
                  prevQuestion={handleSetPrevQuestion}
                  nextQuestion={handleSetNextQuestion}
                  isOneByOneMode={isOneByOneMode}
                  lastItemIndex={test.questions.length - 1}
                />
              ) : (
                <div className={classes.questionList}>
                  {test.questions.map((question, i) => (
                    <QuestionPreview
                      key={`question-${question['question-id']}`}
                      question={question}
                      index={i}
                      onUpdate={handleUpdateAnswer}
                      onImagePreview={(src): void => imagePreview.open(src)}
                    />
                  ))}
                </div>
              )}
            </div>


            <div className={classes.actions}>
              <Paper className={classes.progress}>
                <div className={classes.progressBar}>
                  <div className={classes.percent} style={{ width: `${progress}%` }} />
                </div>
                <div className={classes.fontSize12}>{progress}%</div>
              </Paper>

              <Paper className={classes.buttons}>
                <button className={classes.btn} type="button" onClick={handleClose} onMouseEnter={openCompleteConfirm}>
                  <CloseIcon className={classes.btnIcon} />
                  <p>Закрыть</p>
                </button>

                <button
                  className={cn(classes.btn, { [classes.animate]: progress === MAX_PROGRESS })}
                  type="button"
                  onClick={onClickFinish}
                >
                  <DoneIcon className={classes.btnIcon} />
                  <p>Завершить оценку</p>
                </button>
              </Paper>

              <Paper className={classes.timer}>
                {timer || '00:00:00'}
              </Paper>
            </div>
          </div>

          <Confirm
            {...confirm.props}
            className={classes.confirm}
            title="Внимание!"
            summary={<p dangerouslySetInnerHTML={{ __html: confirmMessage }} />}
            confirmLabel="Завершить оценку!"
            onConfirm={handleFinishTest}
            onCancel={handleCloseConfirm}
            onClose={handleCloseConfirm}
          />
          <ImageModal {...imagePreview.props} size="xl" />

          <Modal {...attemptsModal.props} onClose={attemptsModal.close} color="orange" className={classes.modal}>
            <CloseIcon className={classes.closeIcon} onClick={attemptsModal.close} />
            <WarningV2Icon className={classes.confirmIcon} />
            <div className={classes.confirmContent}>
              <p className={classes.fontSize24}>Внимание!</p>
              <p>У вас осталась последняя попытка, чтобы пройти оценку! Ее результаты пойдут в зачет.</p>
            </div>
            <Button variant="warning" size="sm" onClick={attemptsModal.close}>
              Ок
            </Button>
          </Modal>

          <Modal {...timerModal.props} onClose={handleCloseTimerModal} color="orange" className={classes.modal}>
            <CloseIcon className={classes.closeIcon} onClick={handleCloseTimerModal} />
            <AlarmIcon color="#F7B055" className={classes.confirmIcon} />
            <div className={classes.confirmContent}>
              <p className={classes.fontSize24}>Внимание!</p>
              <p>{getRemainingTime(timeLeft)} {modalOptions?.message}</p>
            </div>
            <Button
              variant="warning"
              size="sm"
              onClick={modalOptions?.action === 'finish' ? handleFinishTest : handleCloseTimerModal}
            >
              {modalOptions?.btnLabel}
            </Button>
          </Modal>

          <Modal
            open={showModalTimer}
            className={classes.modal}
          >
            <header className={classes.modalHeader}>
              <Timer color="#F7B055" className={classes.confirmIcon} />
              <CloseIcon className={classes.closeIcon} onClick={handleCloseTimeLeftModal} />
            </header>
            <div>
              <h1 className={classes.modalTitle}>Внимание!</h1>
              <p>
                Время прохождения тестирования превышает время до окончания этапа. Тестирование завершится автоматически
                по истечении времени
              </p>
            </div>
            <div className={classes.modalTimerBlock}>
              <p>У вас осталось:</p>
              <div className={classes.modalTimer}>
                <div className={classes.modalTimerContainer}>
                  <div className={classes.modalTimerValue}>{hour}</div>
                  <p className={classes.modalTimerLabel}>{declensionByNumber(Number(hour), ['час', 'часа', 'часов'], true)}</p>
                </div>
                <div className={classes.modalTimerDivider}>:</div>
                <div className={classes.modalTimerContainer}>
                  <div className={classes.modalTimerValue}>{minute}</div>
                  <p className={classes.modalTimerLabel}>{declensionByNumber(Number(minute), ['минута', 'минуты', 'минут'], true)}</p>
                </div>
              </div>
            </div>
            <Button
              variant="warning"
              size="sm"
              onClick={handleCloseTimeLeftModal}
            >
              Понятно
            </Button>
          </Modal>
        </div>
      )}
    </Block>
  );
});
