import { FC, Fragment, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, Modal } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Checkbox } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/checkbox';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/context.store';
import { CloseIcon } from '../icons/close';
import { UserIcon } from '../icons/userIcon';
import { useResumeModalStyles } from './use-styles';

export const ResumeModal: FC = observer(() => {
  const {
    users: { redirectToResumeService, updateAgreements, builder },
  } = useAppStore();

  const { agreements } = builder.data;
  const [isLoading, setIsLoading] = useState(false);
  const [resumeIsApproved, setResumeIsApproved] = useState(false);

  const resumeModal = useModalState();
  const classes = useResumeModalStyles();

  const resumeApproveHandler = (): void => {
    if (!isLoading) {
      setResumeIsApproved((prev) => !prev);
    }
  };

  const handleRedirect = useCallback(async(): Promise<void> => {
    setIsLoading(true);
    await redirectToResumeService();
    setIsLoading(false);
  }, [redirectToResumeService]);

  const handleModalOpen = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (agreements.resume) {
      handleRedirect();
    }
    else {
      resumeModal.open();
    }
  }, [agreements.resume, isLoading, handleRedirect, resumeModal]);

  const handleUpdateAgreements = async(): Promise<void> => {
    const data = { ...builder.data.agreements, resume: resumeIsApproved };
    setIsLoading(true);
    await updateAgreements(data);
    setIsLoading(false);
    resumeModal.close();
    handleRedirect();
  };

  return (
    <Fragment>
      <span className={classes.link} onClick={handleModalOpen}>
        <UserIcon className={classes.icon} />
        <span className={classes.label}>Поколение ИТ</span>
      </span>

      {createPortal(
        <Modal
          {...resumeModal.props}
          color="blue"
          className={cn(classes.root, 'scrollbar')}
        >
          <header onClick={resumeModal.close}>
            <CloseIcon className={classes.closeIcon} />
          </header>
          <div className={classes.modalBody}>
            <main className={classes.main}>
              <h1 className={classes.title}>Внимание!</h1>
              <div className={classes.content}>
                <p>
                  Этот сервис позволит вам сформировать свое резюме, в которое будут добавлены результаты прохождения
                  оценки уровня сформированности компетенций. Данное резюме будет добавлено в реестр, где будет
                  просматриваться индустриальными, государственными компаниями и ИТ-компаниями для поиска соискателей на
                  вакантные места
                </p>
                <p>
                  В функционале сервиса есть возможность сделать резюме доступным для просмотра при помощи кнопки
                  «Сделать доступным» с иконкой в виде глаза.
                </p>
                <p>
                  При нажатии на кнопку «Сделать доступным» вы соглашаетесь опубликовать свое резюме в общей базе
                  данных. Вы можете закрыть доступ к своему резюме, чтобы оно не было видно потенциальным работодателям.
                </p>
                <p>
                  Дополнительно у вас будет возможность скачать резюме в формате *.pdf для собственного использования.
                </p>
              </div>
            </main>
            <label className={classes.checkbox}>
              <Checkbox checked={resumeIsApproved} onChange={resumeApproveHandler} />
              <span>Я соглашаюсь с условиями раздела “Поколение ИТ“</span>
            </label>
            <Button className={classes.btn} disabled={!resumeIsApproved || isLoading} onClick={handleUpdateAgreements}>
              Продолжить
            </Button>
          </div>
        </Modal>,
        document.body,
      )}
    </Fragment>
  );
});
