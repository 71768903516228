import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    padding: {
      top: 8,
      bottom: 8,
      left: 20,
      right: 12,
    },
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.gloomyBlue.main,
    '&:hover': {
      backgroundColor: '#F5FBFF',
    },
  },
  icon: {
    minWidth: 20,
    maxWidth: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  active: {
    backgroundColor: '#F5FBFF',
  },
  purple: {
    color: '#7B61FF',
  },
  green: {
    color: '#15B012',
  },
  orange: {
    color: '#E26C3C',
  },
  yellow: {
    color: '#F7B055',
  },
  mobileHidden: {},
  '@media (max-width: 1320px)': {
    root: {
      position: 'static',
      padding: '0 28px',
      flexDirection: 'row',
    },
    link: {
      padding: '16px 20px',
      fontWeight: 600,
      gap: 11,
    },
    label: {
      font: {
        family: theme.fontFamily.monserrat,
        size: 14,
      },
      lineHeight: '16px',
    },
  },
  '@media (max-width: 640px)': {
    root: {
      padding: 0,
    },
    mobileHidden: {
      display: 'none',
    },
  },
}), { name: 'SideBar' });
