import { FC } from 'react';
import { Modal, ModalHeader } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { CloseIcon } from '../../../icons/close';
import { useAttentionModalStyles } from './use-styles';

type AttentionModalProps = {
  onClose(): void;
  open: boolean;
};

export const AttentionModal: FC<AttentionModalProps> = ({ open, onClose }) => {
  const classes = useAttentionModalStyles();

  return (
    <Modal open={open} onClose={onClose} size="sm" className={classes.modal}>
      <ModalHeader onClose={onClose} />

      <div className={classes.flex}>
        <div className={classes.icon}>
          <CloseIcon />
        </div>

        <div className={classes.content}>
          <h2 className={classes.title}>Внимание!</h2>
          <p className={classes.description}>Прохождение теста возможно только в одном окне одного устройства</p>
        </div>
      </div>
    </Modal>
  );
};
