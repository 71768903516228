import { sdkGetCourseTestInfo } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { TestingMappers } from '../../mappers/testing.mappers';
import { ITestInfo } from '../../models/entities/testing.models';
import { ICommonStore } from '../../models/stores/common.store';
import { IRootStore } from '../../models/stores/root.store';
import { ILoadTestInfoOpts, ITestingTestInfoState, ITestingTestInfoStore } from '../../models/stores/testing.store';
import { getErrorMessage } from '../../shared/error-message';

export class TestingTestInfoStore implements ITestingTestInfoStore {

  public readonly rootStore: IRootStore;

  public testInfo: ITestingTestInfoState = {
    1: {
      data: null,
      error: undefined,
      loading: true,
    },
    2: {
      data: null,
      error: undefined,
      loading: true,
    },
    3: {
      data: null,
      error: undefined,
      loading: true,
    },
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async loadTestInfo(opts: ILoadTestInfoOpts): Promise<void> {
    if (opts.courseStage === undefined) {
      return;
    }

    const cacheKey = opts.courseStage;

    this.testInfo[cacheKey] = {
      data: null, loading: true, error: undefined,
    };

    const testInfo = this.testInfo[cacheKey] as ICommonStore<ITestInfo>;

    if (!this.rootStore.users.auth.data) {
      return;
    }

    try {
      const response = await sdkGetCourseTestInfo({
        baseURL: this.rootStore.config.collectorAPI,
        token: this.rootStore.users.auth.data.token,
        data: {
          'course-id': opts.courseId,
          'course-stage': opts.courseStage,
          'user-id': this.rootStore.users.builder.data.id,
        },
      });

      const values = response.values[0];

      if (!values) {
        throw new Error('Invalid request');
      }

      runInAction(() => {
        testInfo.data = TestingMappers.getTestInfo(values);
      });
    }
    catch (error) {
      runInAction(() => {
        testInfo.error = getErrorMessage(error);
      });
    }
    finally {
      runInAction(() => {
        testInfo.loading = false;
      });
    }
  }

}
