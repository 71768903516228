/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { QuestionType, UpdateMode, sdkUpdateQuestionAnswer } from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { LOGGER_ACTION } from '../../constants/actions';
import { engineErrorMessages } from '../../models/entities/testing.models';
import { IRootStore } from '../../models/stores/root.store';
import {
  IUpdateQuestionAnswersParams,
  ITestingUpdateQuestionStore,
} from '../../models/stores/testing.store';

export class TestingUpdateQuestionStore implements ITestingUpdateQuestionStore {

  public loading: boolean = false;

  public readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public updateQuestionAnswer = async(opts: IUpdateQuestionAnswersParams): Promise<void> => {
    runInAction(() => {
      this.loading = true;
    });

    const { builderCourse, proctoring, users, config, testing } = this.rootStore;

    const stageOrder = Number(opts.stageOrder);

    const activeStage = builderCourse.data?.stages?.find((stage) => stage.order === stageOrder);

    if (activeStage?.is_procto && opts.mode !== UpdateMode.open) {
      await proctoring.postSnapshots(activeStage._id);
    }

    const data = {
      'user-id': users.builder.data.id,
      'course-id': builderCourse.data?._id,
      'course-stage': stageOrder,
      'question-id': opts.questionId,
      type: opts.type,
      'answer-id': opts.answerId,
      'user-answer': opts.userAnswer,
      mode: opts.mode,
    };

    try {
      const response = await sdkUpdateQuestionAnswer({
        baseURL: config.collectorAPI,
        token: users.auth.data.token,
        data,
      });

      !opts.mode && this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.CLICK_VARIANT_ANSWER,
        request: { data, response },
      });

      if (response.errors.length > 0) {
        throw new Error(engineErrorMessages[response.errors[0].key] || 'Неизвестная ошибка сервера');
      }

      if (opts.mode === UpdateMode.open) {
        return;
      }

      // update local state

      const userTest = testing.userTest;

      const questionIndex = userTest.data.questions.findIndex((el) => el['question-id'] === opts.questionId);
      const question = userTest.data.questions[questionIndex];

      if (!question) {
        return;
      }

      const isPlural = question.type === QuestionType.plural;

      if (isPlural && opts.mode === UpdateMode.delete) {
        question['user-answer-id'] = question['user-answer-id'].filter(
          (answer) => answer !== opts.answerId,
        );
      }
      else if (isPlural && typeof opts.answerId === 'string') {
        const answerIds = question['user-answer-id'] || [];
        answerIds.push(opts.answerId);
        question['user-answer-id'] = answerIds;
      }
      else if (question.type === QuestionType.open) {
        question['user-answer'] = opts.userAnswer;
      }
      else {
        question['user-answer-id'] = opts.answerId;
      }

      userTest.data.questions[questionIndex] = { ...question };

      runInAction(() => {
        if (userTest.data) {
          userTest.data = { ...userTest.data };
        }
      });
    }
    catch (error) {
      toast({
        type: 'error',
        text: error.message,
      });
    }
    finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

}
