import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    },
    error: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: 8,
    },
    errorBlock: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: 8,
      color: '#EB5757',
    },
    errorMessage: {
      font: {
        size: 20,
        weight: 600,
      },
      lineHeight: '28px',
    },
    link: {
      color: theme.palette.primary.main,
      fontSize: 14,
      lineHeight: '20px',
    },
  }),
  { name: 'Block' },
);
