import { IProctoringListRes } from '@unione-pro/unione.assmnt.sdk.webapp';
import {
  PROCTO_STATUSES,
} from '../constants/proctoring.constants';
import {
  IProctoringListWithProctoStatus,
} from '../models/entities/proctoring.models';

export class ProctoringMappers {

  public static addProctoStatus(data: IProctoringListRes): IProctoringListWithProctoStatus {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const items = data.items.map((item) => ({ ...item, status: PROCTO_STATUSES[item.result] || PROCTO_STATUSES.disabled }));
    return { ...data, items };
  }

}
