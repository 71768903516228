import { AxiosError } from 'axios';
import { HttpCodes } from '../models/requests/http.models';

type ErrorData = {
  message: string | string[];
};

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};

export const getErrorCode = (error: unknown): number | undefined => {
  if (error instanceof AxiosError) {
    return error.response.status;
  }

  return undefined;
};

export const getErrorMessages = (error: unknown): string => {
  if (!(error instanceof AxiosError)) {
    return String(error);
  }

  const { data, status } = error.response || {};

  if (status === HttpCodes.forbidden) {
    return error.message;
  }

  if (typeof data === 'string') {
    const { message }: ErrorData = JSON.parse(data);

    return Array.isArray(message) ? message.join(', ') : message;
  }

  if ('message' in data) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return data.message;
  }

  return error.message;
};
