import { FC, Fragment, useEffect, useState } from 'react';
import { QRCertsType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/button';
import { Checkbox } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/checkbox';
import { CloseIcon } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/icons/close';
import { Modal, ModalFooter, ModalHeader } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/context.store';
import { useStyles } from './use-styles';

export const QRCodeModal: FC = observer(() => {
  const classes = useStyles();
  const { isOpen, open, close } = useModalState<false>();

  const [agreed, setAgreed] = useState(false);

  const { users } = useAppStore();
  const { updateAgreements } = users;
  const { data, loading } = users.builder;

  const certsAgreement = data?.agreements?.qr_certs;

  useEffect(() => {
    if (certsAgreement === QRCertsType.None && !loading) {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  const handleSave = (): void => {
    updateAgreements({
      resume: data.agreements.resume,
      qr_certs: agreed ? QRCertsType.Agreed : QRCertsType.Disagreed,
    });

    close();
  };

  const handleClose = (): void => {
    if (agreed || certsAgreement !== QRCertsType.None) {
      close();
    }
  };

  return (
    <Fragment>
      {certsAgreement !== QRCertsType.Agreed && (
        <Button variant="outlined" onClick={(): void => open()} className={classes.agreement}>
          Принять соглашение
        </Button>
      )}

      <Modal className={classes.modal} open={isOpen} onClose={handleClose}>
        <ModalHeader className={classes.modalHeader}>
          {certsAgreement !== QRCertsType.None && (
            <CloseIcon onClick={handleClose} width={28} height={28} className={classes.closeIcon} />
          )}
        </ModalHeader>
        <div className={classes.content}>
          <div className={classes.header}>
            <p className={classes.title}>Внимание!</p>
            <p className={classes.text}>Для размещения на сертификатах QR-кода необходимо согласиться с условиями:</p>
          </div>
          <div className={classes.agreementWrapper}>
            <Checkbox checked={agreed} onClick={(): void => setAgreed((prev) => !prev)} className={classes.checkbox} />
            <p>
              Я соглашаюсь опубликовать сведения о сертификате, полученном по итогам прохождения оценки уровня
              сформированности компетенций, с предоставлением доступа к нему по QR.
            </p>
          </div>
        </div>
        <ModalFooter className={classes.footer}>
          <Button
            className={classes.button}
            onClick={handleSave}
            disabled={!agreed && certsAgreement === QRCertsType.Disagreed}
            size="sm"
          >
            Сохранить
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
});
